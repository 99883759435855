import { Add, BedOutlined, ShowerOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import NEW_PROPERTY from "./Property_New";
import PROPERTY_DETAILS from "./Property_Details";
import { Get_Seller_Properties } from "../../Scripts/API";
import toast from "react-hot-toast";
import ClickbaitPopup from "../../components/ClickBait";
function MY_PROPERTIES() {
  const [New_Property, set_New_Property] = useState(false);
  const [Property_Details, set_Property_Details] = useState(false);
  const [prpoert_detail_data, set_Property_Details_data] = useState({});
  const [Properties, set_Properties] = useState([]);
  const [loading, set_loading] = useState(true);

  const fetchdata = async () => {
    set_loading(true);
    try {
      const data = await Get_Seller_Properties();
      if (data.message) {
        throw new Error(data.message);
      }
      set_Properties(data);
    } catch (err) {
      toast.error(err.message);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return Property_Details ? (
    <PROPERTY_DETAILS
      set_Property_Details={set_Property_Details}
      d={prpoert_detail_data}
    />
  ) : New_Property ? (
    <NEW_PROPERTY set_New_Property={set_New_Property} />
  ) : (
    <div className="  w-screen px-[10px] md:px-[70px]">
      <HEADER set_New_Property={set_New_Property} loading={loading} />
      <ClickbaitPopup
        text="Find Out Who's Bidding on Your Properties"
        link="/seller_bids"
      />

      <div className=" flex items-center justify-center flex-col sm:grid lg:grid-cols-4 md:grid-cols-3  sm:grid-cols-2 grid-cols-1 gap-8 mb-16 min-h-80">
        {Properties?.map((d, i) => (
          <ITEM
            i={i}
            key={i}
            d={d}
            set_Property_Details={set_Property_Details}
            set_Property_Details_data={set_Property_Details_data}
          />
        ))}
      </div>
    </div>
  );
}

export default MY_PROPERTIES;

function HEADER(props) {
  return (
    <div className="flex items-center gap-5 my-8">
      <h1 className="text-xl md:text-2xl font-medium">
        My Property
        {props.loading && (
          <span className="loading loading-sm loading-spinner" />
        )}
      </h1>
      <button
        onClick={() => props.set_New_Property(true)}
        className="btn bg-s1 text-white font-medium rounded-md border-none text-sm md:text-base"
      >
        <Add /> Add Property
      </button>
    </div>
  );
}

function ITEM(props) {
  return (
    <div
      onClick={() => {
        props.set_Property_Details(true);
        props.set_Property_Details_data(props.d);
        window.scrollTo({ top: 0, behavior: "instant" });
      }}
      className="max-w-[373px] h-[423px] bg-white rounded-[20px] shadow-md text-white hover:cursor-pointer"
    >
      <div className="bg-blue-300 h-[246px] rounded-t-[20px] relative overflow-hidden">
        {" "}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundImage: `url(${props.d.Images[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
      <div className="bg-p1 h-[177px]  rounded-b-[20px] p-4 flex flex-col justify-between">
        <div>
          <h1 className="font-semibold">{props.d.property_title}</h1>
          <h1>{props.d.propert_address}</h1>
        </div>
        <div className="flex items-center  justify-between">
          <div>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <BedOutlined />
                <h1>{props.d.bedrooms} bed</h1>
              </div>
              <div className="flex gap-2">
                <ShowerOutlined />
                <h1>{props.d.bathrooms} bath</h1>
              </div>
            </div>
          </div>
          <h1 className="font-semibold text-2xl">${props.d.asking_price}</h1>
        </div>
      </div>
    </div>
  );
}
