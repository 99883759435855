function SECTION_HEADER(props) {
  return (
    <div className="text-center ">
      <h1 className="text-[24px] sm:text-[40px]  font-semibold capitalize">
        {props.header}
      </h1>
      <h1 className="hidden sm:block font-medium text-[16px] text-[#8F90A6] capitalize">
        {props.discription}
      </h1>
      <h1 className="font-medium text-[16px] text-[#8F90A6] block sm:hidden capitalize">
        {props.discription_sm}
      </h1>
    </div>
  );
}

export default SECTION_HEADER;
