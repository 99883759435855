import { useState } from "react";
import { Send_ContactUs_Email } from "../../Scripts/API";
import toast from "react-hot-toast";
import { Helmet, HelmetProvider } from "react-helmet-async";

function CONTACT_US() {
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [phone, set_phone] = useState("");
  const [how, set_how] = useState("");
  const [msg, set_msg] = useState("");
  const [sending, set_sending] = useState(false);

  async function Handlesubmit() {
    set_sending(true);
    const x = await Send_ContactUs_Email({
      User_Name: name,
      User_Email: email,
      User_Phone: phone,
      User_How: how,
      User_Message: msg,
    });
    if (x === "ok") {
      toast.success("Submitted Successfully");
      set_msg("");
      set_email("");
      set_name("");
      set_phone("");
      set_how("");
    } else {
      toast.error("Couldn't send " + x);
    }

    set_sending(false);
  }

  return (
    <HelmetProvider>
      <Helmet>
          <title>Welcome to Reagent Bid</title>
          <meta name="description" content="Brief description for better SEO and knowledge panel" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Welcome to Reagent Bid",
                "url": "https://www.reagentbid.com/Contact%20us",
                "description": "Connect With Trusted Real Estate Agents. Receive multiple proposals from the competing agents and chose the right agent at the lowest cost to you",
                "publisher": {
                  "@type": "Real Estate Agent",
                  "name": "Reagent Bid",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.yourwebsite.com/logo.jpg"
                  }
                }
              }
            `}
          </script>
        </Helmet>
    <div className="w-screen flex items-center justify-center px-[10px] md:px-[70px] ">
      <div className="w-[545px] mb-20">
        <h1 className="text-2xl font-medium text-center my-10">Contact Us</h1>
        <div className=" flex flex-col gap-10">
          <input
            disabled={sending}
            value={name}
            onChange={(e) => {
              set_name(e.target.value);
            }}
            className="w-full p-2 border border-[#E0E0E0] h-[50px] placeholder:text-sm"
            placeholder={"Name *"}
          />
          <input
            disabled={sending}
            value={email}
            onChange={(e) => {
              set_email(e.target.value);
            }}
            className="w-full p-2 border border-[#E0E0E0] h-[50px] placeholder:text-sm"
            placeholder={"Email *"}
          />
          <input
            disabled={sending}
            value={phone}
            onChange={(e) => {
              set_phone(e.target.value);
            }}
            className="w-full p-2 border border-[#E0E0E0] h-[50px] placeholder:text-sm"
            placeholder={"Phone *"}
          />
          <div className="w-full p-2 border border-[#E0E0E0] h-[50px] placeholder:text-sm bg-white ">
            <select
              disabled={sending}
              value={how}
              onChange={(e) => {
                set_how(e.target.value);
              }}
              className="w-full h-full text-sm outline-none"
            >
              <option value=""> How did you find us?</option>
              <option value="Google">Google</option>
              <option value="Social Media">Social Media</option>
              <option value="Friend/Family">Friend/Family</option>
              <option value="Advertisement">Advertisement</option>
              <option value="Other">Other</option>
            </select>
          </div>{" "}
          <textarea
            disabled={sending}
            value={msg}
            onChange={(e) => {
              set_msg(e.target.value);
            }}
            className="w-full p-2 rounded-sm h-[150px] border border-[#E0E0E0] placeholder:text-sm bg-white"
            placeholder={"Please enter your message"}
          ></textarea>
          <button
            disabled={
              !name ||
              !email ||
              !phone ||
              !how ||
              !msg ||
              name === "" ||
              email === "" ||
              phone === ""
            }
            onClick={() => {
              Handlesubmit();
            }}
            className="btn w-full p-2 border border-[#E0E0E0] h-[50px] placeholder:text-sm bg-s1 text-white font-semibold outline-none border-none "
          >
            Send
          </button>
        </div>
      </div>
    </div>
    </HelmetProvider>
  );
}

export default CONTACT_US;
