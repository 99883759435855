import { ArrowBack } from "@mui/icons-material";

function Back(props) {
  return (
    <div
      onClick={() => props.back(false)}
      className="flex gap-4 items-center text-p2 my-10 w-fit hover:cursor-pointer"
    >
      <ArrowBack />
      <h1 className="text-lg font-medium">{props.heading}</h1>
    </div>
  );
}

export default Back;
