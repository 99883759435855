import SECTION_HEADER from "../Section_Heading";
import iphone from "../../images/iPhone Space Black Front.png";
function SECTION_2() {
  return (
    <div className="px-[10px] md:px-[70px]  w-full py-7">
      <SECTION_HEADER
        header={"How It Works"}
        discription={"See how our platform works"}
        discription_sm={"See how our platform works"}
      />

      <div className="flex md:grid grid-cols-2 gap-32  md:p-0  w-full ">
      <div className="items-center w-full justify-center gap-[56px] md:gap-2 flex flex-col py-7">
        <POINT
          no={"1"}
          head={"Add Your Property"}
          dis={"Put all necessary information and Add Listing of your property."}
        />
        <POINT
          no={"2"}
          head={"Find a trusted Agent"}
          dis={"Receive several offers from agents, and choose the one that fits your needs and your budget."}
        />
        <POINT
          no={"3"}
          head={"Final Step"}
          dis={"Make arrangements to meet a chosen agent and sign a listing."}
        />
      </div>


        <div className="hidden md:block relative h-[771px] right-[70px]">
          <div className="bg-[#E8EDF6] w-[640px] h-[535px] rounded-[50px] top-28 z-1 absolute overflow-visible"></div>
          <div className="absolute w-[771px] h-[771px] overflow-visible left-[-64px]">
            <img src={iphone} alt="iphone_image" className="z-50 " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SECTION_2;

function POINT(props) {
  return (
    <div className="flex flex-col items-center md:items-start justify-center md:grid grid-cols-10 gap-2  w-full ">
      <h1 className="text-xl md:text-4xl font-medium text-[#8F90A6] ">
        {props.no}
      </h1>
      <div className="col-span-9 flex flex-col w-full items-center justify-center text-center md:text-start md:items-start">
        <h1 className="text-xl md:text-4xl font-medium ">{props.head}</h1>
        <h1 className="text-sm md:text-base font-medium max-w-[295px] lg:max-w-[390px]  text-[#8F90A6] lg:w-[390px] pt-4">
          {props.dis}
        </h1>
      </div>
    </div>
  );
}
