import { DeleteForever, Warning } from "@mui/icons-material";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function AWSUpload(props) {
  const [FILEs, setfiles] = useState([]);
  const [Images, setimages] = useState([]);
  const MBs = 0.5;
  const Size_Limit = MBs * 1024 * 1024;
  const removeFile = (index) => {
    setfiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setimages((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.set_FILEs(FILEs);
  }, [FILEs]);
  const onSelectFiles = async (event) => {
    const files = event.target.files;
    if (!Files_Ok(files)) {
      props.set_FILEs([]);
      setimages([]);
      return setfiles([]);
    }
    if (!Size_Limit_Ok(files, Size_Limit)) {
      props.set_FILEs([]);
      setimages([]);
      return setfiles([]);
    }

    setfiles([]);
    setimages([]);
    props.set_FILEs([]);
    for (const file of files) {
      setimages((f) => [...f, URL.createObjectURL(file)]);
      const data = await convertToBase64(file);
      const new_file = { name: file.name, data: data };
      setfiles((prevFiles) => [...prevFiles, new_file]);
      props.set_FILEs((prevFiles) => [...prevFiles, new_file]);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  return (
    <div>
      <Toaster />
      <input
        hidden
        id="prop_images"
        type="file"
        accept="image/*"
        multiple
        onChange={onSelectFiles}
      />
      <label
        className="btn btn-sm outline-0 border-none  text-white text-xs font-semibold bg-s1 px-[20px] py-[7px] rounded-[40px]"
        htmlFor="prop_images"
      >
        Upload Images
      </label>
      <h1 className="text-sm flex items-center gap-2 text-gray-500 mt-1">
        <Warning style={{ fontSize: "18px", color: "orange" }} /> 500KB (0.5MB)
        Image size Limit
      </h1>
      <div className="flex  gap-2">
        {Images.map((image, i) => (
          <div
            key={i}
            className="w-16 border rounded flex items-center justify-center bg-slate-50 my-2 relative"
          >
            <button
              onClick={() => {
                removeFile(i);
              }}
              className="absolute  text-red-500 rounded-full -top-3 -right-3"
            >
              <DeleteForever style={{ fontSize: "24px" }} />
            </button>
            <img src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AWSUpload;

function Size_Limit_Ok(files, Size_Limit) {
  for (const file of files) {
    if (file.size > Size_Limit) {
      toast.error("File: " + file.name + " is larger than Size_Limit", {
        duration: 6000,
      });
      return false;
    }
  }

  return true;
}
function Files_Ok(files) {
  if (files.length === 0 || !files) {
    toast.error("No files selected");
    return false;
  }
  return true;
}
