import { useState } from "react";
import { ModalHeader } from "../Modal_Header";
import {Delete_Property} from "../../Scripts/API"
import toast from "react-hot-toast";

function Delete_prop(props){
    const[loading,setloading] = useState(false);
    
    async function handleclick(){
        setloading(true);
        const x = await Delete_Property(props.property._id);
        if(x){
            window.location.reload();

        }
        else{
            toast.error("unable to delte")
        }


    }
        return(
        <dialog id="DelProp" className="modal">
      <div className="modal-box">
      <ModalHeader heading="Delete Property" />
      <div className="flex flex-col items-center justify-center gap-2 my-4">
        <h1 className="font-semibold text-2xl text-center ">Are you sure you want to delete this property?</h1>
        <button onClick={handleclick} disabled={loading} className="btn bg-red-500 text-white w-32 my-4 text-xl">Yes</button></div>
        </div>
        </dialog>

    )
}
export default Delete_prop;