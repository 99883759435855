import React, { useState, useEffect } from "react";
import "../css/clickbat.css";
import { Close, Warning } from "@mui/icons-material";

const ClickbaitPopup = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    // Show the popup after a short delay to trigger the animation
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // delay to ensure CSS is applied

    return () => clearTimeout(timer);
  }, []);

  const handleClose = (e) => {
    e.stopPropagation();
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 300); // duration of the closing animation
  };

  const handleClick = () => {
    window.location.href = props.link;
  };

  return (
    isVisible && (
      <div
        className={`clickbait-popup bg-white shadow-lg ${
          isClosing ? "closing" : "visible"
        }`}
        onClick={handleClick}
      >
        <span
          className=" fixed top-0 right-1 border-none "
          onClick={handleClose}
        >
          <Close style={{ fontSize: "16px" }} />
        </span>
        <div className="m-2 flex   gap-2">
          <Warning className="text-yellow-500 animate-bounce " />
          <h1 className="text-sm text-center ">{props.text}</h1>
        </div>
      </div>
    )
  );
};

export default ClickbaitPopup;
