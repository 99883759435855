import axios from "axios";
const FormData = require("form-data");

const url = process.env.REACT_APP_SERVER_URL;
const getToken = () => {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
};
const handleRequest = async (config) => {
  try {
    config.headers = { Authorization: `Bearer ${await getToken()}` };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return new Error("Server Error " + error);
  }
};

export async function SignIn(email, password) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/signin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email: email, password: password },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function SignUp(Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/signup`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Add_Property(Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/property/add`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { property: Data },
    };
    return await handleRequest(config);
  } catch (error) {
    console.log(error.message);
    return error;
  }
}
export async function Upload_Property_Images(_id, FILEs) {
  try {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/test/upload/${_id}`, {
      FILEs,
    });
    return true;
  } catch (error) {
    return false;
  }
}
export async function Delete_Property_Images(FILEs) {
  try {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/test/delete`, {
      FILEs,
    });
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
}

export async function Upload_Agent_resume(name) {
  try {
    const fileInput = document.getElementById("agent_resume");
    const file = fileInput.files[0];
    console.log(file);

    const fd = new FormData();
    fd.append("name", +name);
    fd.append("file", file);
    await axios.post("http://localhost:5001/upload_resume", fd);

    return true;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export async function Upload_Agent_Resume(FILE) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/test/uploadresume`,
      { FILE }
    );
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function Upload_New_Agent_Resume_URL(data) {
  try {
    const config = {
      method: "put",
      url: `${url}/property/bid/resume`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await handleRequest(config);
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export async function Submit_Bid(_id, Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/property/submitbid`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { property_id: _id, bid_data: Data },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}
export async function Get_Seller_Properties() {
  try {
    const config = {
      method: "get",
      url: `${url}/property/seller/get`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Search_Property(type, location) {
  try {
    const config = {
      method: "post",
      url: `${url}/property/search`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { type: type, location: location },
    };

    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Seller_Profile() {
  try {
    const config = {
      method: "get",
      url: `${url}/user/sellerprofile`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}
export async function Agent_Profile() {
  try {
    const config = {
      method: "get",
      url: `${url}/user/agentprofile`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}
export async function Update_Seller_Profile(Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/update/seller`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Update_Agent_Profile(Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/update/agent`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function My_Bids() {
  try {
    const config = {
      method: "get",
      url: `${url}/property/mybids`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function MY_Property_Bids() {
  try {
    const config = {
      method: "get",
      url: `${url}/property/mypropertybids`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Get_Property(_id) {
  try {
    const config = {
      method: "get",
      url: `${url}/property/get/${_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Get_Proeprty_address() {
  try {
    const config = {
      method: "get",
      url: `${url}/property/address`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Send_ContactUs_Email(Data) {
  try {
    const config = {
      method: "post",
      url: `${url}/email/contactus`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }
}

export async function Google_Profile_Request(token) {
  try {
    const config = {
      method: "get",
      url: `https://www.googleapis.com/oauth2/v3/userinfo`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    config.headers = { Authorization: `Bearer ${token}` };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function Signup_Google(jwt, role) {
  console.log(role);
  try {
    const config = {
      method: "post",
      url: `${url}/user/signup/google?role=${role}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function SignIn_Google(token) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/signin/google`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function Reset_Password(email) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/resetpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email: email },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function Update_Password(data) {
  try {
    const config = {
      method: "post",
      url: `${url}/user/updatepassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function Edit_Property(_id, data) {
  try {
    const config = {
      method: "put",
      url: `${url}/property/edit/${_id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await handleRequest(config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function Edit_bid(property_id, bid_id, data) {
  try {
    const config = {
      method: "put",
      url: `${url}/property/bid/edit`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { property_id, bid_id, data },
    };
    const response = await handleRequest(config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function Delete_Property(id){
  try {
    const config = {
      method: "delete",
      url: `${url}/property/del/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }

}
export async function _Delete_Bid(pid,bid){
  try {
    const config = {
      method: "post",
      url: `${url}/property/delbid`,
      headers: {
        "Content-Type": "application/json",
      },
      data:{pid:pid,bid:bid}
    };
    return await handleRequest(config);
  } catch (error) {
    return error;
  }

}