import { GoogleLogin } from "@react-oauth/google";
import { ModalHeader } from "../Modal_Header";
import { useState } from "react";
import toast from "react-hot-toast";
import { Signup_Google } from "../../Scripts/API";
import { Google } from "@mui/icons-material";

function GoogleSignup() {
  const [role, set_role] = useState("");

  async function onSuccess(x) {
    if (role === "" || !role) {
      return toast.error("No Role Selected");
    }
    if (!x.credential) {
      toast.error(x);
    }
    const res = await Signup_Google(x.credential, role);
    if (res.sucess) {
      toast.success("Account Created");
      window.location.href = "/login";
    } else {
      toast.error(res.msg);
    }
  }
  async function onError(x) {
    toast.success("un-valid google account");
  }

  return (
    <dialog className="modal" id="Google Signup">
      <div className="modal-box">
        <ModalHeader heading="Sign up With Google" />
        <div className="flex flex-col gap-4 my-5 w-full">
          <DROP_DOWN role={role} set_role={set_role} />
          {role === "" ? (
            <button className="btn" disabled>
              Sign Up with Google <Google />
            </button>
          ) : (
            <div>
              <h1>{role}</h1>
              <GoogleLogin
                onSuccess={(x) => onSuccess(x)}
                onError={(x) => onError(x)}
              />
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
}

export default GoogleSignup;

function DROP_DOWN(props) {
  return (
    <div className="w-full">
      <h1 className="mb-2">Select Role</h1>
      <select
        value={props.role}
        onChange={(e) => {
          props.set_role(e.target.value);
        }}
        className="border rounded-md border-gray-300 w-full p-2 outline-none"
      >
        <option value={""}>None</option>
        <option value={"agent"}>Property Agent</option>
        <option value={"seller"}>Property Seller</option>
      </select>
    </div>
  );
}
