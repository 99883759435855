import { useEffect, useState } from "react";
import INPUT from "../Input";
import { ModalHeader } from "../Modal_Header";
import toast from "react-hot-toast";

function ChangePassword(props) {
  const [match, setmatch] = useState(false);
  const [loading, set_loading] = useState(false);
  const [new_pwd_inp, setnew_pwd_inp] = useState("");
  const [confirm_pwd_inp, setconfirm_pwd_inp] = useState("");

  useEffect(() => {
    if (
      new_pwd_inp !== confirm_pwd_inp &&
      new_pwd_inp !== "" &&
      confirm_pwd_inp !== ""
    ) {
      setmatch(true);
    } else {
      setmatch(false);
    }
  }, [new_pwd_inp, confirm_pwd_inp]);

  const Save_Profile = async () => {
    try {
      set_loading(true);
      let new_proflie = {
        User_Name: props.data.User_Name,
        User_Phone: props.data.User_Phone,
        User_Password: confirm_pwd_inp,
      };
      const data = await props.update(new_proflie);

      if (data === true) {
        toast.success("password updated");
        setnew_pwd_inp("");
        setconfirm_pwd_inp("");
        document.getElementById("change_pwd").close();
      }
    } catch (e) {
      toast.error("error updating password");
    } finally {
      set_loading(false);
    }
  };

  return (
    <dialog className="modal" id="change_pwd">
      <div className="modal-box">
        <div>
          <ModalHeader heading="Change Password" />

          <div className="flex flex-col gap-2 my-4">
            <INPUT
              disabled={loading}
              value={new_pwd_inp}
              onChange={setnew_pwd_inp}
              type={"password"}
              placeholder="New password"
            />
            <INPUT
              disabled={loading}
              value={confirm_pwd_inp}
              onChange={setconfirm_pwd_inp}
              type={"password"}
              placeholder="Confirm New password"
            />
          </div>
          <div className="text-red-500 text-sm ">
            {match && <h1>! password don't match </h1>}
          </div>

          <button
            onClick={Save_Profile}
            disabled={
              loading || match || new_pwd_inp === "" || confirm_pwd_inp === ""
            }
            className="btn w-full bg-s1 text-white"
          >
            Change Password
          </button>
        </div>
      </div>
    </dialog>
  );
}

export default ChangePassword;
