import { useEffect, useState } from "react";
import { ModalHeader } from "../Modal_Header";
import { Edit_bid } from "../../Scripts/API";
import toast from "react-hot-toast";

function EditBid(props) {
  const [loading, setloading] = useState(false);

  const [Agent_Commision, set_Agent_Commision] = useState("");
  const [Agent_Budget, set_Agent_Budget] = useState("");
  const [Contract_Length, set_Contract_Length] = useState("");
  const [Agent_INFO, setAgent_INFO] = useState("");

  const [data, setdata] = useState(props.data);

  async function post() {
    try {
      setloading(true);
      const res = await Edit_bid(props.data.property_id, props.data.bid_id, {
        Agent_Budget,
        Agent_Commision,
        Contract_Length,
        Agent_INFO,
      });

      if (res) {
        toast.success("Bid Updated");
        window.location.reload();
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    setdata(props.data);
    set_Agent_Budget(props.data?.budget);
    set_Agent_Commision(props.data?.commision_rate);
    set_Contract_Length(props.data?.contract_length);
    setAgent_INFO(props.data?.Agent_INFO);
  }, [props.data]);
  return (
    <dialog id="EditBid" className="modal">
      <div className="modal-box">
        <ModalHeader heading="Edit Bid" />
        <div className="flex flex-col gap-2 my-5">
          <div className="grid grid-cols-2">
            <h1>Property Name</h1>
            <input
              disabled
              value={data?.propert_title || ""}
              className="p-1 rounded-md border"
            />
          </div>

          <div className="grid grid-cols-2">
            <h1>Commision Rate </h1>
            <input
              onChange={(e) => {
                set_Agent_Commision(e.target.value);
              }}
              type="text"
              value={Agent_Commision || ""}
              className="p-1 rounded-md border"
            />
          </div>

          <div className="grid grid-cols-2">
            <h1>Budget </h1>
            <input
              onChange={(e) => {
                set_Agent_Budget(e.target.value);
              }}
              type="text"
              value={Agent_Budget || ""}
              className="p-1 rounded-md border"
            />
          </div>

          <div className="grid grid-cols-2">
            <h1>Contract Length </h1>
            <input
              onChange={(e) => {
                set_Contract_Length(e.target.value);
              }}
              type="text"
              value={Contract_Length || ""}
              className="p-1 rounded-md border"
            />
          </div>

          <div className="grid ">
            <h1 className="my-1 ">Why should Buyer choose You? </h1>
            <textarea
              className="border rounded-md p-1 min-h-44"
              value={Agent_INFO}
              onChange={(e) => {
                setAgent_INFO(e.target.value);
              }}
            />
          </div>
        </div>
        <button
          onClick={post}
          disabled={
            !Agent_Budget || !Agent_Budget || !Contract_Length || loading
          }
          className="btn w-full bg-p1 text-white"
        >
          Update
        </button>
      </div>
    </dialog>
  );
}

export default EditBid;
