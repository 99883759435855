import {
  BedOutlined,
  DeleteForever,
  Edit,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  ShowerOutlined,
} from "@mui/icons-material";
import { DimensionsIcon } from "@radix-ui/react-icons";
import Back from "../../components/Back";
import { useState } from "react";
import EditProperty from "../../components/Modals/Edit_Property";
import Enlarge_Image from "../../components/Modals/Enlarge_Image";
import Delete_prop from "../../components/Modals/Delete_Property";

function PROPERTY_DETAILS(props) {
  return (
    <div className="w-screen md:px-[70px] px-[10px] mb-16">
      <Delete_prop property={props.d}/>
      <EditProperty property={props.d} />
      <h1 className="font-medium text-2xl mt-8">Property Details</h1>
      <Back heading={"Back To Properties"} back={props.set_Property_Details} />

      <div className="flex flex-col   gap-10   w-full ">
        <PROPERTY_MAIN d={props.d} />
        <DETAILS d={props.d} />
        <EXTRA_DETAILS d={props.d} />
      </div>
    </div>
  );
}

export default PROPERTY_DETAILS;

function PROPERTY_MAIN(props) {
  const [img_index, set_img_index] = useState(0);
  const [img_count, set_img_count] = useState(props.d.Images.length);
  return (
    <div className="md:h-[282px]  text-[#274868] md:flex items-center gap-10">
      <Enlarge_Image imag={props.d.Images[img_index]} />
      <div>
        <div>
          <div className="bg-blue-300 w-[342px] h-[282px] rounded-[16px] flex justify-center items-end p-2 relative overflow-hidden">
            <div className="w-full h-full absolute flex items-center justify-center">
              <span className="loading-spinner loading loading-lg "></span>
            </div>
            <div
              onClick={() => {
                document.getElementById("img_cnt").showModal();
              }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: `url(${props.d.Images[img_index]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: 1,
              }}
            />
            <div className="flex gap-2 z-10">
              <button
                onClick={() => set_img_index(img_index - 1)}
                disabled={img_index + 1 === 1}
                className="rounded-full bg-white btn btn-xs p-0 m-0"
              >
                <KeyboardArrowLeftRounded />
              </button>
              <div className="bg-white rounded-full px-2">
                <h1>
                  {img_index + 1}/{img_count}
                </h1>
              </div>
              <button
                onClick={() => set_img_index(img_index + 1)}
                disabled={img_index + 1 === img_count}
                className="rounded-full bg-white btn btn-xs p-0 m-0"
              >
                <KeyboardArrowRightRounded />
              </button>
            </div>
          </div>
        </div>
      </div>
<div>

  
        <button   onClick={() => {
              document.getElementById("DelProp").showModal();
            }}
          className="btn bg-red-500 text-white font-medium text-lg border-none rounded-md flex md:hidden  mt-2 "><DeleteForever/>Delete Proeprty</button>
      <button
        onClick={() => {
          document.getElementById("edit property").showModal();
        }}
        className="btn bg-s1 text-white font-medium text-lg border-none rounded-md mt-2 md:hidden"
      >
        <Edit /> <h1>Edit Property Details</h1>
      </button></div>
      <div className="  flex  flex-col gap-4 w-full m-4 md:m-0">
        <h1 className="text-2xl font-semibold">{props.d.property_title}</h1>
        <h1>{props.d.propert_address}</h1>
        <div className="flex gap-4">
          <h1 className="flex items-center gap-2">
            <BedOutlined />
            {props.d.bedrooms} bed
          </h1>
          <h1 className="flex items-center gap-2">
            <ShowerOutlined />
            {props.d.bathrooms} bath
          </h1>
          <h1 className="flex items-center gap-2">
            <DimensionsIcon />
            <h1>{props.d.property_area.$numberDecimal}</h1> sq. ft.
          </h1>
        </div>
        <div className=" flex justify-between items-center">
          <div>
            <h1>Asking Price</h1>
            <h1 className="font-medium text-3xl">${props.d.asking_price}</h1>
          </div>
          <div>

          <button
            onClick={() => {
              document.getElementById("edit property").showModal();
            }}
            className="btn bg-s1 text-white font-medium text-lg border-none rounded-md md:flex hidden"
          >
            <Edit /> <h1>Edit Property Details</h1>
          </button>          <button   onClick={() => {
              document.getElementById("DelProp").showModal();
            }}
          className="btn bg-red-500 text-white font-medium text-lg border-none rounded-md md:flex hidden w-full my-2 "><DeleteForever/>Delete Proeprty</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function DETAILS(props) {
  return (
    <div className="col-span-3 bg-[#F7FBFD] p-10">
      <h1 className="text-p1 font-semibold ">Property Details</h1>
      <h1 className="text-[#5D5E75] mt-4">{props.d.property_detail}</h1>
    </div>
  );
}

function EXTRA_DETAILS(props) {
  return (
    <div className="col-span-1 bg-[#F7FBFD] p-10">
      <h1 className="text-p1 font-semibold ">Extra Details</h1>
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Balcony</h1>
          <h1> {props.d.Balcony ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Garden</h1>
          <h1>{props.d.Garden ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Parking</h1>
          <h1>{props.d.Parking ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Garage</h1>
          <h1>{props.d.Garage ? "Yes" : "No"}</h1>
        </div>
      </div>
    </div>
  );
}
