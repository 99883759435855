import { Close } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

function DRAWER() {
  
  const closeDrawer = () => {
    document.getElementById("my-drawer-4").checked = false;
  };

  return (
    <div className="drawer drawer-end z-50 block sm:hidden">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content"></div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>

        <div className="min-h-full menu bg-white">
          <label htmlFor="my-drawer-4" className="cursor-pointer">
            <Close />
          </label>
          <div onClick={closeDrawer}>
            <LINKS />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DRAWER;

function LINKS() {
  const ActiveStyle = "underline font-[600] text-s1";
  const InActiveStyle = "font-[500]";
  return (
    <div className="gap-[49px] flex flex-col min-w-40  m-10">
      <NavLink
        to={"/login"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Sign In
      </NavLink>
      <NavLink
        to={"/"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Home
      </NavLink>

      <NavLink
        to={"/Listings"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Listings
      </NavLink>

      <NavLink
        to={"/Contact us"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Contact us
      </NavLink>
    </div>
  );
}
