import { Star, SupervisedUserCircle } from "@mui/icons-material";
import SECTION_HEADER from "../Section_Heading";

function SECTION_4() {
  return (
    <div className="w-full px-[10px] md:px-[70px] py-7">
      <SECTION_HEADER
        header={"What users say about our service"}
        discription={
          "See what users say about their experience with our services"
        }
        discription_sm={
          "See what users say about their experience with our services"
        }
      />
      <div className="flex flex-col gap-4 my-14 w-full">
        <div className="flex md:flex-row flex-col items-center gap-4 justify-end w-full ">
          <CARD
            review={
              "Working with Jay was an absolute pleasure. He was knowledgeable, attentive, and always available to answer my questions. He made the home-selling process smooth, stress-free, and affordable.  Highly recommended!"
            }
          />
          <CARD
            review={
              "Dora is a top-notch real estate professional. Her expertise and dedication were evident from our first meeting. She helped us sell our home and negotiated a great deal. We couldn't be happier!"
            }
          />
        </div>
        <div className="flex gap-4 md:flex-row flex-col  items-center  w-full ">
          <CARD
            review={
              "I highly recommend Nate. He was incredibly patient and understanding of our needs. His attention to detail and commitment to finding us the perfect buyer was unparalleled. Thank you!"
            }
          />
          <CARD
            review={
              "Kathy went above and beyond to ensure we sold our property quickly, as we were under a time pressure. Her knowledge of the local market and her negotiation skills were invaluable. We are so grateful for her hard work and dedication."
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SECTION_4;

function CARD(props) {
  return (
    <div className="bg-white drop-shadow-md rounded-md p-10  w-[343px] h-[370px] md:w-[492px] md:h-[314px]  ">
      <div className="flex items-center gap-2 w-full">
        <SupervisedUserCircle style={{ width: "100px", height: "100px" }} />
        <div className="grid grid-flow-row grid-rows-2 ">
          <h1 className="font-medium text-2xl "></h1>
          <div>
            <Star className="text-p1" />
            <Star className="text-p1" />
            <Star className="text-p1" />
            <Star className="text-p1" />
            <Star className="text-p1" />
          </div>
        </div>
      </div>
      <h1 className="text-[#8F90A6] ">{props.review}</h1>
    </div>
  );
}
