import { Helmet, HelmetProvider } from "react-helmet-async";
import SECTION_1 from "../../components/Home_Page/Section_1";
import SECTION_2 from "../../components/Home_Page/Section_2";
import SECTION_3 from "../../components/Home_Page/Section_3";
import SECTION_4 from "../../components/Home_Page/Section_4";
import BANNER from "../../components/Home_Page/banner";

function Home() {
  return (
    <HelmetProvider>
      <div className="gap-10 py-5 w-screen flex flex-col items-center justify-center bg-[#FFFFFF]">
        <Helmet>
          <title>Welcome to Reagent Bid</title>
          <meta name="description" content="Brief description for better SEO and knowledge panel" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Welcome to Reagent Bid",
                "url": "https://www.reagentbid.com/",
                "description": "Connect With Trusted Real Estate Agents. Receive multiple proposals from the competing agents and chose the right agent at the lowest cost to you",
                "publisher": {
                  "@type": "Real Estate Agent",
                  "name": "Reagent Bid",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.yourwebsite.com/logo.jpg"
                  }
                }
              }
            `}
          </script>
        </Helmet>

        <BANNER />
        <SECTION_1 />
        <SECTION_2 />
        <SECTION_3 />
        <SECTION_4 />
      </div>
    </HelmetProvider>
  );
}

export default Home;





// import SECTION_1 from "../../components/Home_Page/Section_1";
// import SECTION_2 from "../../components/Home_Page/Section_2";
// import SECTION_3 from "../../components/Home_Page/Section_3";
// import SECTION_4 from "../../components/Home_Page/Section_4";
// import BANNER from "../../components/Home_Page/banner";
// function Home() {
//   return (
//     <div className="gap-10 py-5  w-screen flex flex-col   items-center justify-center bg-[#FFFFFF]">
//       <BANNER />
//       <SECTION_1 />
//       <SECTION_2 />
//       <SECTION_3 />
//       <SECTION_4 />
//     </div>
//   );
// }

// export default Home;
