import { AccountCircle, EditOutlined } from "@mui/icons-material";
import { Agent_Profile, Update_Agent_Profile } from "../../Scripts/API";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ChangePassword from "../../components/Modals/Change_Password";
function PROFILE_SELLER() {
  const [loaded_profile, set_loaded_profile] = useState({});

  const [User_Name, set_User_Name] = useState("");
  const [User_Phone, set_User_Phone] = useState("");
  const [User_Password, set_User_Password] = useState("");

  const [Edit, set_Edit] = useState(true);
  const [loading, set_loading] = useState(false);
  const [updating, set_updating] = useState(false);

  const fetchdata = async () => {
    try {
      set_loading(true);
      const data = await Agent_Profile();
      set_loaded_profile(data);
      set_User_Name(data.User_Name || "");
      set_User_Password(data.User_Password || "");
      set_User_Phone(data.User_Phone || "");
    } catch (e) {
    } finally {
      set_loading(false);
    }
  };

  const Save_Profile = async () => {
    try {
      if (User_Name === "" || User_Phone === "") {
        return toast.error("unvalid profile data");
      }
      set_updating(true);
      set_Edit(true);
      let new_proflie = {
        User_Name,
        User_Phone,
        // User_Password: loaded_profile.User_Password,
      };
      const data = await Update_Agent_Profile(new_proflie);

      if (data === true) {
        toast.success("profile updated");
      }
    } catch (e) {
      toast.error("error updating");
    } finally {
      set_updating(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <div className="flex items-center justify-center w-screen md:px-[70px] px-[10px] ">
      <ChangePassword update={Update_Agent_Profile} data={loaded_profile} />
      <div className="md:border w-[710px] h-[746px] rounded my-4 md:items-center justify-center flex">
        <div className="w-[360px]  flex flex-col gap-4">
          <div className="flex flex-col items-center justify-center gap-2">
            <h1 className="text-2xl font-medium">My Profile</h1>
            <AccountCircle style={{ fontSize: 96 }} />
            {!Edit ? (
              <div className="grid grid-cols-2 gap-4">
                <button
                  className="btn btn-ghost border border-[#D0D7DE] btn-sm rounded-md"
                  onClick={() => set_Edit(true)}
                >
                  Cancle
                </button>
                <button
                  onClick={() => Save_Profile()}
                  className="btn btn-ghost border border-[#D0D7DE] btn-sm rounded-md"
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                className="btn btn-ghost border border-[#D0D7DE] btn-sm rounded-md"
                onClick={() => {
                  set_Edit(!Edit);
                }}
              >
                <EditOutlined style={{ fontSize: 16 }} />
                Edit
              </button>
            )}
          </div>

          <h1 className="text-sm font-medium">Your Name</h1>
          <input
            className="border rounded-sm p-2 w-full disabled:text-[#8F90A6]"
            disabled={Edit}
            value={User_Name}
            onChange={(e) => {
              set_User_Name(e.target.value);
            }}
          ></input>

          <h1 className="text-sm font-medium">Phone Number</h1>
          <input
            className="border rounded-sm p-2 w-full disabled:text-[#8F90A6]"
            disabled={Edit}
            value={User_Phone}
            onChange={(e) => {
              set_User_Phone(e.target.value);
            }}
          ></input>
          <h1 className="text-sm font-medium">Password</h1>
          <div>
            <input
              type="password"
              disabled={true}
              className=" border rounded-sm p-2 w-full disabled:text-[#8F90A6]"
              value={User_Password}
              onChange={(e) => set_User_Password(e.target.value)}
            ></input>
            <div className="flex justify-end mt-2">
              <h1
                onClick={() => {
                  document.getElementById("change_pwd").showModal();
                }}
                className="btn btn-ghost hover:underline text-sm font-medium  text-s1"
              >
                Change Password
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PROFILE_SELLER;
