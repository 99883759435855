import {
  DeleteForever,
  Download,
  FolderOutlined,
  TrySharp,
  Upload,
  UploadFile,
  Warning,
} from "@mui/icons-material";
import { ModalHeader } from "../Modal_Header";
import toast from "react-hot-toast";
import { useState } from "react";
import {
  Upload_Agent_Resume,
  Upload_New_Agent_Resume_URL,
} from "../../Scripts/API";

function EditResumeFile(props) {
  const [loading, setlaoding] = useState(false);
  const [FILE, setfile] = useState();
  const MBs = 2;
  const Size_Limit = MBs * 1024 * 1024;

  const onSelectFiles = async (event) => {
    const file = event.target.files[0];
    if (!Files_Ok(file)) {
      return setfile();
    }
    if (!Size_Limit_Ok(file, Size_Limit)) {
      return setfile();
    }
    const data = await convertToBase64(file);
    const new_file = { name: file.name, data: data };
    setfile(new_file);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  function Download_Resume(url) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async function Upload() {
    try {
      setlaoding(true);

      const url = await Upload_Agent_Resume(FILE);
      await Upload_New_Agent_Resume_URL({
        propertyId: props.bid.property_id,
        bidderId: props.bid.bid_id,
        newResumeUrl: url,
      });
      window.location.reload();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setlaoding(false);
    }
  }

  return (
    <dialog className="modal" id="editresume">
      <input
        hidden
        id="resume"
        type="file"
        accept="application/pdf"
        onChange={onSelectFiles}
      />
      <div className="modal-box relative">
        {loading && (
          <div className="w-full h-full absolute top-0 right-0 bg-[rgba(0,0,0,0.7)] text-white  flex items-center justify-center">
            <span className="loading loading-spinner"></span>
          </div>
        )}
        <ModalHeader heading="Resume" />
        <div className="flex  gap-2  justify-center my-4 flex-col ">
          <button
            onClick={() => {
              Download_Resume(props.bid.Agent_Resume);
            }}
            className="btn bg-s1 text-white "
          >
            <Download /> Download Resume
          </button>

          <button
            onClick={() => Upload()}
            disabled={!FILE}
            className="btn bg-s1 text-white "
          >
            <UploadFile /> Upload Resume
          </button>
        </div>
        <div>
          <label
            htmlFor="resume"
            className="btn btn-sm btn-ghost p-0 m-0  text-gray-500  "
          >
            <FolderOutlined /> Select New Resume
          </label>
        </div>
        {FILE && (
          <button
            onClick={() => {
              document.getElementById("resume").value = null;
              setfile();
            }}
            className="btn btn-sm btn-ghost p-0 m-0  text-red-500  "
          >
            <DeleteForever />
            <h1>{FILE.name}</h1>
          </button>
        )}
        <h1 className="text-sm flex items-center gap-2 text-gray-500 mt-1 ">
          <Warning style={{ fontSize: "18px", color: "orange" }} /> 2000KB (2MB)
          File size Limit
        </h1>
      </div>
    </dialog>
  );
}

export default EditResumeFile;

function Size_Limit_Ok(file, Size_Limit) {
  if (file.size > Size_Limit) {
    toast.error("File: " + file.name + " is larger than Size_Limit", {
      duration: 6000,
    });
    return false;
  }

  return true;
}
function Files_Ok(files) {
  if (!files) {
    document.getElementById("resume").value = null;
    toast.error("No files selected");
    return false;
  }
  return true;
}
