import {
  ChatOutlined,
  CheckCircleOutline,
  SupportAgent,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function SECTION_1() {
  return (
    <div className="flex flex-col items-center   w-full px-[10px] md:px-[70px] py-7">
      <div className="text-center w-[312px] sm:w-full ">
        <h1 className="text-[24px] sm:text-[40px]  font-semibold">
          Get Multiple Agents Competing For Your Listing
        </h1>
        <h3 className="hidden sm:block font-bold text-[16px] text-[#8F90A6]">
          We are here to help you, We have the most experienced and qualified
          Agents.
        </h3>
        <h1 className="hidden sm:block font-bold text-[20px] text-[#8F90A6] italic">
        *Your property address and your information is not shown to the agent until you choose to reveal it*
      </h1>
        <h1 className="font-medium text-[16px] text-[#8F90A6] block sm:hidden">
          Some most recent added properties
        </h1>
      </div>

      <div className="flex items-center justify-center flex-col md:py-14 ">
        <div className="py-10 flex flex-col items-center justify-center md:grid grid-cols-1 md:grid-cols-3  lg:gap-16 gap-2">
          <TILE />
          <TILE2 />
          <TILE3 />
        </div>
        <Link
          to={"/create account?role=seller"}
          className="btn rounded-[4px] outline-none border-none bg-p1 text-white min-w-[286px] min-h-[58px] font-semibold text-lg "
        >
          Join Now As Seller
        </Link>
      </div>
    </div>
  );
}

export default SECTION_1;

function TILE() {
  return (
    <div className="border rounded-md drop-shadow-md p-8 gap-8 grid grid-flow-row grid-rows-3 justify-center  max-w-[342px] sm:max-w-[353px] h-[331px]  sm:h-[357px]">
      <div className="w-[79px] h-[79px] rounded-full bg-s3 flex items-center justify-center">
        <SupportAgent style={{ fontSize: "34px" }} />
      </div>
      <h1 className=" font-medium w-[195px] text-xl sm:text-2xl">
        Access to Top Agents
      </h1>
      <h1 className="text-[#8F90A6]  ">
        Connect with experienced and qualified real estate agents in your area.
      </h1>
    </div>
  );
}
function TILE2() {
  return (
    <div className="border rounded-md drop-shadow-md p-8 gap-8 grid grid-flow-row grid-rows-3 justify-center max-w-[342px]  sm:max-w-[353px] w-[353px] md:w-auto min-h-[331px]  sm:h-[357px]">
      <div className="w-[79px] h-[79px] rounded-full bg-s3 flex items-center justify-center">
        <ChatOutlined style={{ fontSize: "34px" }} />
      </div>
      <h1 className="font-medium w-[195px]  text-xl sm:text-2xl">
        Streamlined Communication
      </h1>
      <h1 className="text-[#8F90A6] ">Effortlessly communicate with agents.</h1>
    </div>
  );
}

function TILE3() {
  return (
    <div className="border rounded-md drop-shadow-md p-8 gap-8 grid grid-flow-row grid-rows-3 justify-center max-w-[342px]  sm:max-w-[353px] min-h-[331px]  sm:h-[357px]">
      <div className="w-[79px] h-[79px] rounded-full bg-s3 flex items-center justify-center">
        <CheckCircleOutline style={{ fontSize: "34px" }} />
      </div>
      <h1 className=" w-[195px]  text-xl sm:text-2xl font-medium">
        Hassle-Free Experience
      </h1>
      <h1 className="text-[#8F90A6] ">
        Simplify the Experience of Listing Your Property by Choosing the Agent
        Who Will Provide Best Services At Lowest Commission Rate.
      </h1>
    </div>
  );
}
