import {
  AccountCircle,
  ArrowBack,
  Download,
  Image,
  OpenInNew,
  Phone,
} from "@mui/icons-material";

function AGENT_DETAILS(props) {
  return (
    <div className="bg-white h-screen w-screen block md:hidden overflow-scroll">
      <ModalHeader />
      <div className="flex flex-col w-full ">
        <AGENT_INFO data={props.data} />
        <DETAILS data={props.data} />
      </div>
    </div>
  );
}

export default AGENT_DETAILS;

function AGENT_INFO(props) {
  return (
    <div className="bg-w    mx-4 mt-4 px-4 pt-8 ">
      <div>
        <div className="flex flex-col gap-2 items-center justify-start  mb-4">
          <div>
            <AccountCircle style={{ width: 96, height: 96 }} />
          </div>

          <h1 className="text-lg font-medium">{props.data.agent_name}</h1>
          <h1 className="font-medium text-p1 text-base">
            {props.data.agent_email}
          </h1>

          <div className="flex flex-col items-center gap-4">
            <button
              onClick={() => {
                const message =
                  "I hope this message finds you well.\n\nI am writing to inform you that I have received your bid for my property listed on Reagent.com. I am interested in your offer and would like to discuss it further.\n\nPlease let me know a convenient time for you to have a conversation.\n\nThank you for your interest.\n\nBest regards,\n\n[Your Name]\n[Your Contact Information]";

                const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
                  props.data.agent_email
                )}&body=${encodeURIComponent(message)}`;
                window.open(mailtoLink, "_blank", "noopener,noreferrer");
              }}
              className="btn border-none bg-s1 text-white rounded-md"
            >
              <Phone />
              Contact This Agent
            </button>

            <button
              onClick={() => {
                Download_Resume(props.data.agent_resume);
              }}
              className="text-s1 btn btn-ghost text-base font-medium  w-full p-0 m-0 rounded-md"
            >
              <Download />
              Download Agent Resume
            </button>
          </div>
        </div>

        <div>
          <h1 className="text-lg font-medium py-4">Agent Bio</h1>
          <h1 className="text-[#8F90A6]">{props.data.agent_info}</h1>
        </div>
      </div>
    </div>
  );
}
function Download_Resume(url) {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
function DETAILS(props) {
  return (
    <div className=" flex flex-col px-4  m-4">
      <h1 className="text-lg font-medium">Property Details</h1>
      <div className="border p-4 my-4">
        <div className="flex gap-4 my-2 ">
          <div className="">
            <img
              style={{ width: 80, height: 80 }}
              className="rounded-md"
              src={`${props.data.property_img}`}
              alt=">>"
            />{" "}
          </div>
          <div className="text-p1">
            <h1 className="text-lg ">{props.data.property_title}</h1>
            <p className="flex gap-2 items-center">
              <span className="text-sm">Asking Price</span>
              <span className="text-xl font-semibold">
                ${props.data.property_price}
              </span>
            </p>
          </div>
        </div>
        <a
          href={`/property/${props.data.property_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-s1 font-medium text-base flex items-center gap-2 btn btn-ghost w-fit"
        >
          <OpenInNew />
          View Property
        </a>
      </div>

      <div>
        <h1 className="text-lg font-medium ">Bid Details</h1>
        <div className="gap-2  flex flex-col text-base font-medium text-p1 border p-4 my-4">
          <div className="grid grid-cols-2 ">
            <h1>Company Name:</h1>
            <h1>{props.data.company_name}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1>Commission Rate:</h1>
            <h1>{props.data.agent_commision}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1>Lenght of contract:</h1>
            <h1>{props.data.contract_length}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1>Marketing Budget:</h1>
            <h1>${props.data.agent_budget}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

function ModalHeader(props) {
  return (
    <div className="modal-action p-0 m-0  w-full  my-10 px-4">
      <form
        method="dialog"
        className=" flex items-center justify-between w-full text-lg  font-medium "
      >
        <button className="flex  items-center justify-center gap-2 text-p1">
          <ArrowBack /> <h1>Back</h1>
        </button>
      </form>
    </div>
  );
}
