import { Close } from "@mui/icons-material";

export function ModalHeader(props) {
  return (
    <div className="modal-action p-0 m-0  w-full">
      <form
        method="dialog"
        className=" flex items-center justify-between w-full "
      >
        <h1 className="font-bold   text-2xl">{props.heading} </h1>
        <button className="flex  items-center justify-center gap-2 text-p1 outline-none border-none">
          <Close style={{ fontSize: "18px" }} className="" />
        </button>
      </form>
    </div>
  );
}
