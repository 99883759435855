import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import Footer_logo from "../images/Footer_Logo.png";
import { Link } from "react-router-dom";

function FOOTER() {
  return (
    <div className="w-full bg-p1 px-[10px] md:px-[70px] md:h-[537px] flex flex-col justify-between overflow-hidden">
      <div className=" h-full flex md:flex-row flex-col items-center md:gap-32">
        <MAIN />
        <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-4 my-10">
          <LIST
            head={"Product"}
            items={[
              { name: "Home", link: "/" },
              // { name: "Subscription", link: "/" },
              { name: "Listing", link: "/listings" },
              // { name: "Reviews", link: "/listings" },
            ]}
          />
          <LIST
            head={"Company"}
            items={[
              { name: "About", link: "/" },
              { name: "Contact us", link: "/Contact%20us" },
            ]}
          />
          <LIST
            head={"Support"}
            items={[
              { name: "Getting started", link: "/create%20account" },
              { name: "Help center", link: "/Contact%20us" },
              { name: "Report a bug", link: "/Contact%20us" },
            ]}
          />
        </div>
      </div>
      <BOTTOM />
    </div>
  );
}

export default FOOTER;

function MAIN() {
  return (
    <div className="flex flex-col gap-[14px] w-[310px] md:items-start h-[218px]  items-center text-center md:text-start md:my-0 my-10">
      <img src={Footer_logo} alt="Footer_Logo" />
      <h1 className="text-white w-[310px]">
        The Place where you find trusted Real Estate Agents
      </h1>
      <div className="text-white  flex gap-[22px] ">
        <a
          href="https://www.facebook.com/profile.php?id=61563506245157&sk=about"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </a>
        <a
          href="https://twitter.com/reagentbid"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
        <a
          href="https://www.instagram.com/reagentbid/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </a>
        <a
          href="https://www.linkedin.com/company/104099012/admin/dashboard/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </a>
        <a
          href="https://www.youtube.com/@ReagentBid"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTube />
        </a>
      </div>
    </div>
  );
}
function BOTTOM() {
  return (
    <div className="border-t text-white border-white flex justify-between p-4">
      <h1> 2024 RE Agent Bid. All Rights Reserved </h1>
      <p>
        <span>Powered by </span>
        <a
          className="cursor-pointer hover:underline"
          href="https://cyberhaq.com/"
        >
          {" "}
          cyberhaq.com
        </a>
      </p>
    </div>
  );
}

function LIST(props) {
  return (
    <div className="text-white text-lg md:h-[196px]  ">
      <h1 className="font-bold md:mb-8 mb-2">{props.head}</h1>
      <div className="flex flex-col">
        {props.items.map((i, d) => (
          <Link to={i.link} className="mb-2" key={d}>
            {i.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
