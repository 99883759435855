import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";

function BANNER() {
  return (
    <HelmetProvider>
      <div className="h-[633.76px] w-[90%] xl:w-[1300px] rounded-[50px] relative overflow-hidden">
        <Helmet>
          <title>Connect With Trusted Real Estate Agents | Join Now</title>
          <meta
            name="description"
            content="Receive multiple proposals from trusted agents and choose the right agent at the lowest cost. Join now to connect with top real estate agents."
          />
          <meta
            property="og:title"
            content="Connect With Trusted Real Estate Agents | Join Now"
          />
          <meta
            property="og:description"
            content="Receive multiple proposals from competing agents and choose the best one for your property needs."
          />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_SERVER_URL}/download/01714546141152.png`}
          />
          <meta property="og:url" content="https://www.reagentbid.com/" />
          <meta name="robots" content="index, follow" />
        </Helmet>

        <div
          className="sm:grid grid-cols-2"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/download/01714546141152.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
          }}
        >
          <DEFAULT />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default BANNER;

function DEFAULT() {
  return (
    <div className="flex sm:items-start gap-4 px-[10px] sm:px-0 sm:ml-[10%] mt-10 sm:justify-center text-start flex-col h-full">
      <h1 className="text-[34px] text-[#110229] sm:text-[56px] font-medium">
        Connect With Trusted Real Estate Agents
      </h1>
      <h2 className="md:max-w-[450px] text-[14px] sm:text-[18px] font-[400] text-[#8F90A6]">
        Receive multiple proposals from the competing agents and choose the right
        agent at the lowest cost to you.
      </h2>
      <Link
        to="/create account"
        className="w-[131px] sm:w-[206px] h-[46px] sm:h-[58px] text-[16px] sm:text-[18px] font-[600] btn btn-sm bg-p1 border-none outline-none sm:rounded-sm text-white"
      >
        Join Now
      </Link>
    </div>
  );
}






// import { Link } from "react-router-dom";

// function BANNER() {
//   return (
//     <div className=" h-[633.76px] w-[90%] xl:w-[1300px] rounded-[50px]   relative overflow-hidden">
//       <div
//         className="sm:grid grid-cols-2"
//         style={{
//           width: "100%",
//           height: "100%",
//           position: "absolute",
//           top: 0,
//           left: 0,
//           backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/download/01714546141152.png)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           zIndex: 1,
//         }}
//       >
//         <DEFAULT />
//       </div>
//     </div>
//   );
// }

// export default BANNER;

// function DEFAULT() {
//   return (
//     <div className=" flex sm:items-start gap-4  px-[10px] sm:px-0 sm:ml-[10%]   mt-10 sm:justify-center  text-start flex-col h-full ">
//       <h1 className="text-[34px] text-[#110229] sm:text-[56px] font-medium ">
//         Connect With Trusted Real Estate Agents
//       </h1>
//       <h1 className="md:max-w-[450px]  text-[14px] sm:text-[18px] font-[400] text-[#8F90A6]">
//         Receive multiple proposals from the competing agents and chose the right
//         agent at the lowest cost to you
//       </h1>
//       <Link
//         to={"/create account"}
//         className="w-[131px] sm:w-[206px]  h-[46px] sm:h-[58px]  text-[16px] sm:text-[18px] font-[600] btn btn-sm bg-p1 border-none outline-none sm:rounded-sm text-white"
//       >
//         Join Now
//       </Link>
//     </div>
//   );
// }
