import { ArrowBack, Done } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Submit_Bid,
  Upload_Agent_Resume,
  Upload_Agent_resume,
} from "../../Scripts/API";
import AwsPdfUplaod from "../../components/Modals/AWS_PDF_Upload";
import { Helmet, HelmetProvider } from "react-helmet-async";

function LISTING_BID(props) {
  const [Agent_Commision, set_Agent_Commision] = useState();
  const [Agent_Budget, set_Agent_Budget] = useState();
  const [Contract_Length, set_Contract_Length] = useState();
  const [Company_Name, set_Company_Name] = useState("");
  const [Agent_INFO, set_Agent_INFO] = useState("");
  const [Terms_Agreed, set_Terms_Agreed] = useState(false);
  const [Resume, set_Resume] = useState();

  const [uploading, setuploading] = useState(false);
  const handleSubmit = async () => {
    try {
      setuploading(true);
      const Data = {
        Agent_Resume: "",
        Agent_Commision,
        Agent_Budget,
        Contract_Length,
        Company_Name,
        Agent_INFO,
        Terms_Agreed,
      };
      const url = await Upload_Agent_Resume(Resume);
      Data.Agent_Resume = url;
      await Submit_Bid(props.property._id, Data);

      toast.success("Bid submited sucessfully");
      window.location.reload();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setuploading(false);
    }
  };

return (
  <HelmetProvider>
  <Helmet>
          <title>Welcome to Reagent Bid</title>
          <meta name="description" content="Brief description for better SEO and knowledge panel" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Welcome to Reagent Bid",
                "url": "https://www.reagentbid.com/Listings",
                "description": "Connect With Trusted Real Estate Agents. Receive multiple proposals from the competing agents and chose the right agent at the lowest cost to you",
                "publisher": {
                  "@type": "Real Estate Agent",
                  "name": "Reagent Bid",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.yourwebsite.com/logo.jpg"
                  }
                }
              }
            `}
          </script>
    </Helmet>
  <div className="w-screen md:px-[70px] px-[10px] mb-[30px]">
    <BACK set_submit_bid={props.set_submit_bid} />
    <div className="md:px-[40px]">
      <div className="flex gap-2 items-center">
        <h1 className="font-medium text-2xl">Submitting Bid</h1>
        <h1 className="text-xs text-slate-500 italic font-medium">
          ({props.property._id})
        </h1>
      </div>
      <div className="my-[30px] flex flex-col gap-[58px]">
        <div className="flex flex-col gap-[15px] md:gap-[32px]">
          <div className="flex flex-col gap-[10px] items-start">
            <h1 className="text-sm text-[#828282]">Resume *</h1>
            <div className="flex flex-col gap-[10px] items-start">
              <AwsPdfUplaod set_Resume={set_Resume} />
              <span className="flex gap-2">
                <h1>{!Resume ? "No Resume Selected" : "Resume Selected"}</h1>
                <Done
                  className={`${!Resume ? "text-white " : "text-green-500"}`}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[15px] md:gap-[32px] ">
          <h1 className="font-medium text-xl text-[#828282]">Bid Info</h1>
          <div className="flex flex-col md:flex-row  gap-[15px] md:gap-[58px]">
            <input
              disabled={uploading}
              value={Company_Name}
              onChange={(e) => set_Company_Name(e.target.value)}
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm outline-none"
              placeholder={"Company Name *"}
            />
            <input
              disabled={uploading}
              type="text"
              value={Agent_Commision || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_Agent_Commision(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm outline-none"
              placeholder={"Commision Rate *"}
            />
          </div>
          <div className="flex flex-col md:flex-row  gap-[15px] md:gap-[58px]">
            <input
              disabled={uploading}
              type="text"
              value={Contract_Length || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_Contract_Length(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm outline-none"
              placeholder={"Length of contract *"}
            />
            <input
              disabled={uploading}
              type="text"
              value={Agent_Budget || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_Agent_Budget(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              className="w-full p-[12px] border border-[#E0E0E0]h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm outline-none"
              placeholder={"Marketing Budget *"}
            />
          </div>
          <div className="grid md:grid-cols-2 gap-[58px]">
            <textarea
              maxLength={1000}
              disabled={uploading}
              value={Agent_INFO}
              onChange={(e) => set_Agent_INFO(e.target.value)}
              className="w-full p-[12px] border border-[#E0E0E0] placeholder:text-sm outline-none h-[134px] rounded-md md:rounded-none"
              placeholder="Why should Buyer choose You?"
            />
          </div>
          <div className="flex gap-[10px]">
            <input
              disabled={uploading}
              value={Terms_Agreed}
              onChange={(e) => set_Terms_Agreed(!Terms_Agreed)}
              className="checkbox rounded-sm"
              type="checkbox"
            ></input>
            <p>
              <span className="text-[#828282] ">By Clicking </span>
              <span className="text-s1 font-medium">‘Continue’</span>
              <span className="text-[#828282] ">, you agree to the </span>
              <a
                href="/termsandconditions"
                className="text-[#828282] font-medium underline"
              >
                Terms and Conditions
              </a>
              <span className="text-[#828282] "> of our platform.</span>
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                handleSubmit();
              }}
              disabled={
                Company_Name === "" ||
                Agent_Commision === "" ||
                Contract_Length === "" ||
                Agent_Budget === "" ||
                Agent_INFO === "" ||
                !Terms_Agreed ||
                !Resume ||
                uploading
              }
              className="btn bg-s1 text-white font-semibold outline-none border-none w-full sm:w-[308px] h-[50px] rounded-sm"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </HelmetProvider>
);



}

export default LISTING_BID;

function BACK(props) {
  return (
    <div
      onClick={() => props.set_submit_bid(false)}
      className="flex gap-4 items-center text-p2 my-10 w-fit hover:cursor-pointer"
    >
      <ArrowBack />
      <h1 className="text-lg font-medium">Back </h1>
    </div>
  );
}
