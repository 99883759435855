import { DeleteForever, Warning } from "@mui/icons-material";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
function AwsPdfUplaod(props) {
  const [FILE, setfile] = useState();
  const MBs = 2;
  const Size_Limit = MBs * 1024 * 1024;

  const onSelectFiles = async (event) => {
    const file = event.target.files[0];
    if (!Files_Ok(file)) {
      props.set_Resume();
      return setfile();
    }
    if (!Size_Limit_Ok(file, Size_Limit)) {
      props.set_Resume();
      return setfile();
    }
    const data = await convertToBase64(file);
    const new_file = { name: file.name, data: data };
    setfile(new_file);
    props.set_Resume(new_file);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  return (
    <div>
      <Toaster />
      <input
        hidden
        id="resume"
        type="file"
        accept="application/pdf"
        onChange={onSelectFiles}
      />
      <div className="flex  gap-2 items-center ">
        <label
          className="btn btn-sm outline-0 border-none  text-white text-xs font-semibold bg-s1 px-[20px] py-[7px] rounded-[40px]"
          htmlFor="resume"
        >
          Upload Resume
        </label>
        {FILE && (
          <button
            onClick={() => {
              props.set_Resume();
              setfile();
            }}
            className="btn btn-sm btn-ghost p-0 m-0  text-red-500  "
          >
            <DeleteForever />
            <h1>{FILE.name}</h1>
          </button>
        )}
      </div>
      <h1 className="text-sm flex items-center gap-2 text-gray-500 mt-1 ">
        <Warning style={{ fontSize: "18px", color: "orange" }} /> 2000KB (2MB)
        File size Limit
      </h1>
    </div>
  );
}

export default AwsPdfUplaod;

function Size_Limit_Ok(file, Size_Limit) {
  if (file.size > Size_Limit) {
    toast.error("File: " + file.name + " is larger than Size_Limit", {
      duration: 6000,
    });
    return false;
  }

  return true;
}
function Files_Ok(files) {
  if (!files) {
    toast.error("No files selected");
    return false;
  }
  return true;
}
