import { createContext } from "react";
import { Toaster } from "react-hot-toast";
import NAVBAR from "./components/navbar";
import DRAWER from "./components/drawer";
import FOOTER from "./components/Footer";

export const UserContext = createContext();

const DefaultLayout = ({ children }) => {
  return (
    <div className={` bg-white dark:bg-gray-950  w-full `}>
      <Toaster />
      <NAVBAR />
      <DRAWER />
      <div className="flex overflow-hidden ">
        <div className="">
          <div className=" ">
            <main>
              <div className=" flex items-center justify-center   ">
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>
      <FOOTER />
    </div>
  );
};

export default DefaultLayout;
