import {
  BedOutlined,
  Error,
  LocationOnOutlined,
  ShowerOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import LISTING_DETAILS from "./Listing_Details";
import LISTING_BID from "./Listing_Bid";
import { Search_Property, Get_Proeprty_address } from "../../Scripts/API";
import toast from "react-hot-toast";

function Listing() {
  const [View_Deatils, set_View_Deatils] = useState(false);
  const [submit_bid, set_submit_bid] = useState(false);
  const [properties, set_properties] = useState([]);
  const [type, set_type] = useState("");
  const [location, set_location] = useState("");
  const [loading, setloading] = useState(false);
  const [prpoert_detail_data, set_Property_Details_data] = useState({});
  const [names, set_names] = useState([]);
  const [is_logged_in, set_is_log] = useState(false);
  const [possible_search, set_possible_search] = useState([]);

  const fetch_property_names = async () => {
    try {
      const res = await Get_Proeprty_address();
      set_names(res);
    } catch (e) {}
  };
  const fetchdata = async () => {
    try {
      setloading(true);
      const res = await Search_Property(type, location);
      set_properties(res);
    } catch (e) {
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    const x = localStorage.getItem("token");
    const y = sessionStorage.getItem("token");
    if (x || y) {
      return set_is_log(false);
    }
    set_is_log(true);
  }, []);
  useEffect(() => {
    fetchdata();
    fetch_property_names();
  }, []);
  return View_Deatils && submit_bid ? (
    <LISTING_BID
      property={prpoert_detail_data}
      set_submit_bid={set_submit_bid}
    />
  ) : View_Deatils ? (
    <LISTING_DETAILS
      set_View_Deatils={set_View_Deatils}
      set_submit_bid={set_submit_bid}
      d={prpoert_detail_data}
    />
  ) : (
    <div className="w-screen p-2 px-[10px] md:px-[70px] flex flex-col items-center ">
      <div className="w-full items-center text-center p-10">
        <h1 className="text-[28px] font-medium">Property Listing</h1>
      </div>

      <SEARCH
        possible_search={possible_search}
        set_possible_search={set_possible_search}
        names={names}
        loading={loading}
        search={fetchdata}
        type={type}
        set_type={set_type}
        location={location}
        set_location={set_location}
      />

      <div className="w-full my-10 relative">
        <div
          className={`${
            is_logged_in ? "" : "hidden"
          } backdrop-blur-md w-full h-full absolute top-0 left-0 z-10`}
        ></div>
        <h1 className="text-3xl font-medium">
          {properties.length} properties found
        </h1>
        <h1 className="text-[#BFBFC4]">
          Search result shows {properties.length} searches
        </h1>
      </div>

      <div className="relative sm:grid  sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-8 w-full flex flex-col items-center mb-16">
        <div
          className={`${
            is_logged_in ? "" : "hidden"
          } backdrop-blur-md w-full h-full absolute top-0 left-0 z-10 flex  justify-center `}
        >
          <div className="bg-white p-4 rounded-md shadow-md text-2xl w-fit h-fit top-10 absolute flex items-center justify-center m-2 gap-2">
            <Error className="text-red-500" />{" "}
            <h1>You need to sign in first in order to view listing </h1>
          </div>
        </div>
        {properties.map((item, index) => (
          <ITEM
            key={index}
            d={item}
            i={index}
            set_View_Deatils={set_View_Deatils}
            set_Property_Details_data={set_Property_Details_data}
          />
        ))}
      </div>
    </div>
  );
}

export default Listing;

function ITEM(props) {
  return (
    <div
      onClick={() => {
        props.set_View_Deatils(true);
        props.set_Property_Details_data(props.d);
        window.scrollTo({ top: 0, behavior: "instant" });
      }}
      className="max-w-[373px] h-[423px] bg-white rounded-[20px] shadow-md text-white hover:cursor-pointer"
    >
      <div className="bg-blue-300 h-[246px] rounded-t-[20px] relative overflow-hidden">
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundImage: `url(${props.d.Images[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
      <div className="bg-p1 h-[177px]  rounded-b-[20px] p-4 flex flex-col justify-between">
        <div>
          <h1 className="font-semibold">{props.d.property_title}</h1>
          <h1>{props.d.propert_address}</h1>
        </div>
        <div className="flex items-center  justify-between">
          <div>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <BedOutlined />
                <h1>{props.d.bedrooms} bed</h1>
              </div>
              <div className="flex gap-2">
                <ShowerOutlined />
                <h1>{props.d.bathrooms} bath</h1>
              </div>
            </div>
          </div>
          <h1 className="font-semibold text-2xl">${props.d.asking_price}</h1>
        </div>
      </div>
    </div>
  );
}

function SEARCH(props) {
  return (
    <div className="flex flex-col md:flex-row md:gap-[45px] gap-[10px] md:items-end md:justify-center  w-full">
      <div className="flex flex-col gap-[9px]">
        <h1 className="text-[14.66px] font-medium">Property Type</h1>
        <div className="px-2 border border-[#E5E5E5] rounded-md bg-white">
          <select
            value={props.type}
            onChange={(e) => props.set_type(e.target.value)}
            disabled={props.loading}
            className="min-w-[160px] w-full h-[46px] outline-none "
          >
            <option value="">All</option>
            <option value="Apartment">Apartment</option>
            <option value="House">House</option>
            <option value="Condominium">Condominium</option>
            <option value="Townhouse">Townhouse</option>
            <option value="Duplex/Triplex">Duplex/Triplex</option>
            <option value="Mobile/Manufactured">Mobile/Manufactured</option>
            <option value="Commercial">Commercial</option>
            <option value="Industrial">Industrial</option>
            <option value="Land">Land</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col gap-[9px] ">
        <h1 className="text-[14.66px] font-medium "> Location</h1>
        <div className="bg-white rounded-md min-w-[268px] w-full h-[46px] flex items-center gap-2 px-4  border border-[#E5E5E5] relative">
          <LocationOnOutlined />
          <input
            value={props.location}
            onChange={(e) => {
              let value = e.target.value.toUpperCase();
              const regex = /^[a-zA-Z0-9-]{0,9}$/;
              if (!regex.test(value)) {
                return toast.error("Invalid Zipcode");
              }
              props.set_location(value);
            }}
            disabled={props.loading}
            className="outline-none border-none w-full placeholder:text-sm "
            placeholder="Enter a zip code "
          ></input>
          <AUTO_COMPLETE
            possible_search={props.possible_search}
            set_possible_search={props.set_possible_search}
            set_location={props.set_location}
            data={props.names}
            location={props.location}
          />
        </div>
      </div>

      <button
        disabled={props.loading}
        onClick={() => {
          props.set_possible_search([]);
          props.search();
        }}
        className="btn text-white bg-s1 outline-none border-none w-[117px] h-[46px] rounded-[6px]"
      >
        Search
      </button>
    </div>
  );
}
function AUTO_COMPLETE(props) {
  useEffect(() => {
    props.set_possible_search(
      props.location === "" || !props.location
        ? props.set_possible_search([{}])
        : props.data.filter((d) =>
            d.property_address
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(props.location.replace(/\s/g, "").toLowerCase())
          )
    );
  }, [props.location]);
  return (
    <div
      className={`${
        props.possible_search?.length === 0 ||
        props.possible_search?.length === 1 ||
        !props.possible_search
          ? "hidden"
          : ""
      } border rounded-md w-[268px]   absolute top-12 left-0 bg-white z-10  text-sm `}
    >
      {props.possible_search?.map((d, i) => (
        <div
          key={i}
          className="grid grid-cols-5 my-1 px-2 hover:bg-blue-50 hover:cursor-pointer"
          onClick={() => {
            props.set_location(d.property_address);
          }}
        >
          <h1 className="col-span-4 overflow-hidden overflow-ellipsis text-nowrap">
            {d.property_address}
          </h1>
          <h1 className="overflow-hidden overflow-ellipsis text-nowrap">
            {d.property_type}
          </h1>
        </div>
      ))}
    </div>
  );
}
