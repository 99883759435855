import Logo from "../images/logo.png";
import banner from "../images/container.png";
import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useLocation } from "react-router-dom";
import { Update_Password } from "../Scripts/API";

function RESET_PWD() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  if (!code) {
    window.location.replace("/");
  }
  const [pwd, set_pwd] = useState("");
  const [pwd_2, set_pwd_2] = useState("");
  const [loading, setloading] = useState(false);

  async function submit() {
    let data = {
      pwd,
      code,
    };
    try {
      setloading(true);
      const res = await Update_Password(data);
      if (res.Error) {
        return;
      }
      window.location.replace("/");
    } catch (err) {
    } finally {
      setloading(false);
    }
  }

  return (
    <div className="grid md:grid-cols-2  w-screen h-screen p-2 gap-4 overflow-hidden">
      <div className="bg-white flex items-center flex-col justify-center  gap-8">
        <div className="md:fixed  md:top-4 md:left-4 ">

        <Link to={"/"}><img src={Logo} alt="logo" /></Link>        </div>
        <div className="w-full  items-center justify-center flex md:h-full">
          <div className=" flex flex-col  w-[340px] md:w-[400px] md:gap-8 gap-4 ">
            <div className="text-center md:text-start">
              <h1 className="font-semibold md:text-[40px] text-2xl  gap-2 flex flex-col">
                Enter new Password
              </h1>
              <h1 className="text-[#969696] ">
                Please enter your new password
              </h1>
            </div>
            <PWD_FIELD
              loading={loading}
              value={pwd}
              change={set_pwd}
              label={"New Password"}
            />
            <PWD_FIELD
              loading={loading}
              value={pwd_2}
              change={set_pwd_2}
              label={"Confirm Password"}
            />
            <button
              onClick={() => {
                submit();
              }}
              disabled={
                !pwd ||
                !pwd_2 ||
                pwd === "" ||
                pwd === "" ||
                pwd !== pwd_2 ||
                loading
              }
              className="btn rounded-sm bg-s1 text-white font-semibold  border-none text-lg"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
      <div className=" hidden md:flex  justify-end ">
        <img src={banner} alt="banner" />
      </div>
    </div>
  );
}

export default RESET_PWD;

function PWD_FIELD(props) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">
        {props.label}
      </InputLabel>
      <OutlinedInput
        disabled={props.loading}
        value={props.value}
        onChange={(e) => props.change(e.target.value)}
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={props.label}
      />
    </FormControl>
  );
}
