import TextField from "@mui/material/TextField";
import Logo from "../images/logo.png";
import banner from "../images/container.png";
import { Link } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Reset_Password } from "../Scripts/API";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
function FORGOT_PWD() {
  const [email, set_email] = useState("");
  const [loading, set_loading] = useState(false);
  const [sent, set_sent] = useState(false);
  const [resent, set_resent] = useState(0);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (!sent) return;
    if (seconds === 0) return;
    const interval = setInterval(() => {
      if (seconds === 0) {
        return setSeconds(0);
      }
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(interval);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [sent, resent]);

  return (
    <div className="grid md:grid-cols-2  w-screen h-screen p-2 gap-4 overflow-hidden ">
      <Toaster />
      <div className="bg-white flex items-center flex-col justify-center  gap-8">
        <div className="md:fixed  md:top-4 md:left-4 ">

        <Link to={"/"}><img src={Logo} alt="logo" /></Link>        </div>
        <div className="w-full  items-center justify-center flex md:h-full">
          <div className=" flex flex-col  w-[340px] md:w-[400px] md:gap-8 gap-4 ">
            <div className="text-center md:text-start  gap-2 flex flex-col">
              <h1 className="font-semibold md:text-[40px] text-2xl">
                Forgot Password?
              </h1>
              <h1 className="text-[#969696] ">
                Please enter your email, we will send you a Link to reset your
                password.
              </h1>
            </div>
            <TextField
              value={email}
              onChange={(x) => set_email(x.target.value)}
              required
              id="outlined-required"
              label="Email"
              type="email"
              sx={{ borderRadius: 20, MozOutlineRadius: 20 }}
            />
            <button
              onClick={async () => {
                set_loading(true);
                set_sent(true);
                setSeconds(60);
                const x = await Reset_Password(email);
                if (x) {
                  set_loading(false);
                  if (x.status) {
                    return toast.success(
                      "Check your email (including spam folder)"
                    );
                  } else {
                    set_sent(false);
                    set_loading(false);
                    return toast.error(x.msg || "error");
                  }
                }

                set_loading(false);
              }}
              disabled={!email || email === "" || loading || sent}
              className="btn rounded-sm bg-s1 text-white font-semibold  border-none text-lg w-full  disabled:cursor-not-allowed"
            >
              Send Code
            </button>
            <p className="flex items-center justify-center gap-1">
              <span className="text-[#6C6C6C]">did not received it yet? </span>
              <button
                disabled={
                  !email || email === "" || loading || !sent || seconds !== 0
                }
                onClick={async () => {
                  set_loading(true);
                  set_sent(true);
                  setSeconds(60);
                  set_resent(resent + 1);
                  const x = await Reset_Password(email);
                  if (x) {
                    set_loading(false);
                    if (x.status) {
                      return toast.success(
                        "Check your email (including spam folder)"
                      );
                    } else {
                      set_sent(false);
                      set_loading(false);
                      return toast.error(x.msg || "error");
                    }
                  }

                  set_loading(false);
                }}
                className="underline font-medium disabled:text-slate-400 text-s1 cursor-pointer disabled:cursor-not-allowed"
              >
                Resend
              </button>
              {sent && <span>({seconds})</span>}
            </p>
          </div>
        </div>
      </div>
      <div className=" hidden md:flex  justify-end ">
        <img src={banner} alt="banner" />
      </div>
    </div>
  );
}

export default FORGOT_PWD;
