import TextField from "@mui/material/TextField";
import Logo from "../images/logo.png";
import banner from "../images/container.png";
import { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useSearchParams,useNavigate  } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SignUp, Signup_Google } from "../Scripts/API.js";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { ArrowDropDown, Google, Info } from "@mui/icons-material";
import GoogleSignup from "../components/Modals/Google_Signup.jsx";
import { ArrowDownIcon } from "@radix-ui/react-icons";
import { Helmet, HelmetProvider } from "react-helmet-async";

const roles = ["agent", "seller"];

function CREATE_ACCOUNT() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [role, set_role] = useState("");
  const [loading, set_loading] = useState(false);

  const [searchParams] = useSearchParams();
  const _role = searchParams.get("role");

  useEffect(() => {
    if (_role && roles.includes(_role)) {
      set_role(_role);
    }
  }, []);

  async function handleSubmit() {
    try {
      set_loading(true);
      let data = {
        User_Name: name,
        User_Email: email,
        User_Password: password,
        User_Role: role,
      };
      const res = await SignUp(data);
      console.log(res)
      if (res.error) {
        return toast.error(res.error);
      }

      toast.success("Successfully signed up");

      setTimeout(() => {
        navigate('/Login');
      }, 1200);

    } catch (e) {
    } finally {
      set_loading(false);
    }
  }
  return (
    <HelmetProvider>
    <div className="grid md:grid-cols-2  w-screen h-screen p-2 gap-4 overflow-hidden">
    <Helmet>
          <title>Welcome to Reagent Bid</title>
          <meta name="description" content="Brief description for better SEO and knowledge panel" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Welcome to Reagent Bid",
                "url": "https://www.reagentbid.com/create%20account",
                "description": "Connect With Trusted Real Estate Agents. Receive multiple proposals from the competing agents and chose the right agent at the lowest cost to you",
                "publisher": {
                  "@type": "Real Estate Agent",
                  "name": "Reagent Bid",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.yourwebsite.com/logo.jpg"
                  }
                }
              }
            `}
          </script>
    </Helmet>
      <Toaster/>
      <GoogleSignup />
      <div className="bg-white flex items-center flex-col justify-center  gap-4">
        <div className="md:fixed  md:top-4 md:left-4 ">
        <Link to={"/"}><img src={Logo} alt="logo" /></Link>
        </div>
        <div className="w-full  items-center justify-center flex md:h-full">
          <div className=" flex flex-col   w-[340px] md:w-[400px] md:gap-4 gap-2 ">
            <div className="text-center md:text-start  gap-2 flex flex-col">
              <h1 className="font-semibold md:text-[40px] text-2xl">
                Create Account
              </h1>
              <h1 className="text-[#969696] ">
                Create account to enjoy the feature of RE Agent Bid
              </h1>
            </div>
            <TextField
              value={name}
              onChange={(e) => set_name(e.target.value)}
              required
              id="outlined-required"
              label="Your Name"
              type="text"
            />
            <TextField
              value={email}
              onChange={(e) => set_email(e.target.value)}
              required
              id="outlined-required"
              label="Email"
              type="email"
            />
            <DropDown role={role} set_role={set_role} />
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                value={password}
                onChange={(e) => set_password(e.target.value)}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <button
              onClick={() => handleSubmit()}
              disabled={
                loading ||
                !name ||
                !password ||
                !role ||
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
              }
              className="btn rounded-sm bg-s1 text-white font-semibold  border-none text-lg"
            >
              Sign up
            </button>
            <div className="flex items-center justify-center">
              <div className="w-full border-b border-t-1" />
              <label className="px-2">or</label>
              <div className="w-full border-b border-t-1" />
            </div>

            <button
              onClick={() => {
                document.getElementById("Google Signup").showModal();
              }}
              className="btn"
            >
              Continue with google <Google />
            </button>
            <p className="flex items-center justify-center gap-1">
              <span className="text-[#6C6C6C]">Already have an account?</span>
              <Link to="/login">
                <span className="underline font-medium text-s1">Sign in</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className=" hidden md:flex  justify-end ">
        <img src={banner} alt="banner" />
      </div>
    </div>
    </HelmetProvider>
  );
}

export default CREATE_ACCOUNT;

function DROP_DOWN(props) {
  return (
    <div className="">
      <Toaster />
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-name-label">Select Role *</InputLabel>
        <Select
          required
          labelId="demo-multiple-name-label"
          value={props.role}
          onChange={(e) => {
            props.set_role(e.target.value);
          }}
          input={<OutlinedInput label="Select Role" />}
        >
          <MenuItem key={"agent"} value={"agent"}>
            {"Property Agent "}
            <div className="relative bg-red-500">
              <div
                className="tooltip absolute right-0 z-50"
                data-tip="I am a real estate agent, who is looking to show my competitive edge and sell homes"
              >
                <Info />
              </div>
            </div>
          </MenuItem>
          <MenuItem key={"seller"} value={"seller"}>
            {
              "Property Seller – I am looking for a real estate agent, who will best meet my requirements in selling my home"
            }
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function DropDown(props) {
  const [drop, setdrop] = useState(true);
  return (
    <div className="bg-white border border-gray-400 rounded p-2 py-3  relative cursor-pointer select-none">
      <div
        onClick={() => {
          setdrop(!drop);
        }}
        className="flex items-center justify-between  w-full"
      >
        {props.role !== "seller" && props.role !== "agent" && (
          <h1 className="text-[#969696]">Select Role *</h1>
        )}
        {props.role === "seller" && (
          <h1 className="text-black font-medium">Property Seller</h1>
        )}
        {props.role === "agent" && (
          <h1 className="text-black font-medium">Property Agent</h1>
        )}
        <ArrowDropDown />
      </div>
      {drop && (
        <div className="border-t border-gray-400 bg-white absolute top-[100%] left-0 w-full border   z-10 rounded">
          <div
            onClick={() => {
              setdrop(false);
              props.set_role("agent");
            }}
            className="border-b hover:bg-sky-50 p-2 flex justify-between items-center"
          >
            <h1>Property Agent</h1>
            <div
              className="tooltip"
              data-tip="I am a real estate agent, who is looking to show my competitive edge and sell homes"
            >
              <Info />
            </div>
          </div>
          <div
            onClick={() => {
              setdrop(false);
              props.set_role("seller");
            }}
            className="border-b hover:bg-sky-50 p-2 flex justify-between items-center"
          >
            <h1>Property Seller</h1>
            <div
              className="tooltip"
              data-tip="I am looking for a real estate agent, who will best meet my requirements in selling my home"
            >
              <Info />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
