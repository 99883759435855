import { useEffect, useState } from "react";
import Back from "../../components/Back";
import { Add_Property, Upload_Property_Images } from "../../Scripts/API";
import { toast } from "react-hot-toast";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AWSUpload from "../../components/AWS_Image_Upload";

function NEW_PROPERTY(props) {
  const [property_title, set_property_title] = useState("");
  const [propert_address, set_property_address] = useState("");
  const [bedrooms, set_bedrooms] = useState(0);
  const [bathrooms, set_bathrooms] = useState(0);
  const [asking_price, set_asking_price] = useState(0);
  const [property_area, set_property_area] = useState(0);
  const [property_detail, set_property_detail] = useState("");
  const [property_type, set_property_type] = useState("");
  const [Balcony, set_balcony] = useState(false);
  const [Garage, set_garage] = useState(false);
  const [Garden, set_garden] = useState(false);
  const [Parking, set_parking] = useState(false);
  const [submit, set_submit] = useState(false);
  const [agree, set_agree] = useState(false);
  const [images, setImages] = useState([]);

  const [FILEs, set_FILEs] = useState([]);

  async function handleSubmit() {
    try {
      set_submit(true);

      const data = {
        property_title,
        propert_address,
        bedrooms,
        bathrooms,
        asking_price,
        property_area,
        property_detail,
        Balcony,
        Garage,
        Garden,
        Parking,
        agree,
        property_type,
        images,
      };
      const response = await Add_Property(data);
      if (response._id) {
        const x = await Upload_Property_Images(response._id, FILEs);
        if (!x) {
          return toast.error("Error uploading Images");
        }
        toast.success("Property added successfully");
        Reset_All();
      } else if (response === "error") {
        toast.error("Property unable to add");
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      set_submit(false);
      window.location.reload();
    }
  }

  function Reset_All() {
    set_property_title("");
    set_property_address("");
    set_property_type("");
    set_bedrooms(0);
    set_bathrooms(0);
    set_asking_price(0);
    set_property_area(0);
    set_property_detail("");
    set_balcony(false);
    set_garage(false);
    set_garden(false);
    set_parking(false);
    set_agree(false);
    setImages([]);
    set_FILEs([]);
  }

return (
  <div className="w-screen px-[10px] md:px-[70px]">
    <Back back={props.set_New_Property} heading={"Back"} />
    <div className="md:px-[40px]">
      <h1 className="font-medium text-2xl">New property</h1>

      <div className="my-[30px] flex flex-col gap-[58px]">
        <div className="flex flex-col gap-[15px] md:gap-[32px]">
          <h1 className="font-medium text-xl text-[#828282]">
            Property Info
          </h1>
          <div className="grid md:grid-cols-2  gap-[15px] md:gap-[58px]">
            <div className="flex flex-col gap-[10px] items-start">
              <h1 className="text-sm text-[#828282]">Propery Images *</h1>
              <AWSUpload set_FILEs={set_FILEs} />
              <h1 className="text-sm text-[#828282]">
                {FILEs.length} image(s) selected
              </h1>
            </div>
            <DROP_DOWN v={property_type} set={set_property_type} />
          </div>
          <div className="flex flex-col md:flex-row  gap-[15px] md:gap-[58px]">
            <input
              maxLength={40}
              disabled={submit}
              value={property_title}
              onChange={(e) => set_property_title(e.target.value)}
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md placeholder:text-sm "
              placeholder={"Property Title *"}
            />
            <input
              disabled={submit}
              value={propert_address}
              onChange={(e) => {
                let value = e.target.value.toUpperCase();
                const regex = /^[a-zA-Z0-9-]{0,9}$/;
                if (!regex.test(value)) {
                  return toast.error("Invalid Zip Code");
                }

                set_property_address(value);
              }}
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm "
              placeholder={"Property zip code *"}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[15px] md:gap-[58px] ">
            <input
              disabled={submit}
              value={bedrooms || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_bedrooms(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              type="number"
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm "
              placeholder={"Bedrooms *"}
            />
            <input
              disabled={submit}
              value={bathrooms || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_bathrooms(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              type="number"
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm "
              placeholder={"Bathrooms *"}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[15px] md:gap-[58px] ">
            <input
              disabled={submit}
              value={asking_price || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_asking_price(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              type="number"
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm "
              placeholder={"Asking Price *"}
            />
            <input
              disabled={submit}
              value={property_area || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/-/g, '');
                set_property_area(value);
              }}
              onKeyDown={(e) => {
                if (e.key === '-') e.preventDefault();
              }}
              type="number"
              className="w-full p-[12px] border border-[#E0E0E0] h-[46px] md:h-[50px] md:rounded-none rounded-md  placeholder:text-sm "
              placeholder={"Property Area * sq. ft."}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-4 md:gap-[58px]">
            <textarea
              maxLength={1000}
              value={property_detail}
              onChange={(e) => set_property_detail(e.target.value)}
              className=" w-full p-[12px] border border-[#E0E0E0]  placeholder:text-sm outline-none h-[134px] rounded-md md:rounded-none"
              placeholder="Brief detail of the Property and special features"
            />
            <div className="">
              <h1 className="text-xl font-medium mb-4">Extra Details</h1>
              <div className="flex flex-col md:gap-4 gap-2  w-full h-full">
                <div className="grid md:grid-cols-2 gap-2">
                  <SWITCH
                    name={"Balcony"}
                    value={Balcony}
                    set={set_balcony}
                  />
                  <SWITCH name={"Garage"} value={Garage} set={set_garage} />
                </div>
                <div className="grid md:grid-cols-2 gap-2 ">
                  <SWITCH name={"Garden"} value={Garden} set={set_garden} />
                  <SWITCH name={"Parking"} value={agree} set={set_parking} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-[10px]">
              <input
                disabled={submit}
                checked={agree}
                onChange={(e) => set_agree(!agree)}
                className="checkbox rounded-sm"
                type="checkbox"
              />
              <p>
                <span className="text-[#828282] ">I agree to the </span>
                <span className="text-[#828282] font-medium underline">
                  Terms and Conditions
                </span>
                <span className="text-[#828282] "> of our platform.</span>
              </p>
            </div>
            <div>
              <button
                onClick={() => handleSubmit()}
                disabled={
                  !FILEs ||
                  FILEs.length === 0 ||
                  submit ||
                  property_title === "" ||
                  propert_address === "" ||
                  bedrooms === "" ||
                  bathrooms === "" ||
                  asking_price === "" ||
                  property_area === "" ||
                  property_detail === "" ||
                  property_type === "" ||
                  !agree
                }
                className="btn bg-s1 text-white font-semibold outline-none border-none w-full sm:w-[308px] h-[50px] rounded-sm"
              >
                Add Property
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);



}

export default NEW_PROPERTY;

function SWITCH(props) {
  return (
    <div
      className={`flex justify-between items-center md:grid grid-cols-2  ${
        props.active ? "text-black" : "text-[#828282]"
      }`}
    >
      <h1>{props.name}</h1>
      <input
        type="checkbox"
        className="toggle toggle-sm"
        value={props.value}
        onChange={() => props.set(!props.value)}
      />
    </div>
  );
}

function DROP_DOWN(props) {
  return (
    <div className="">
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-name-label">Property Type</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          value={props.v}
          onChange={(e) => {
            props.set(e.target.value);
          }}
          input={<OutlinedInput label="Property Type" />}
        >
          <MenuItem key={""} value={""}>
            {"Un-specfied"}
          </MenuItem>
          <MenuItem key={"House"} value={"House"}>
            {"House"}
          </MenuItem>
          <MenuItem key={"Apartment"} value={"Apartment"}>
            {"Apartment"}
          </MenuItem>
          <MenuItem key={"Condominium "} value={"Condominium "}>
            {"Condominium "}
          </MenuItem>
          <MenuItem key={"Townhouse"} value={"Townhouse"}>
            {"Townhouse"}
          </MenuItem>
          <MenuItem key={"Duplex/Triplex"} value={"Duplex/Triplex"}>
            {"Duplex/Triplex"}
          </MenuItem>
          <MenuItem key={"Mobile/Manufactured"} value={"Mobile/Manufactured"}>
            {"Mobile/Manufactured"}
          </MenuItem>
          <MenuItem key={"Commercial "} value={"Commercial "}>
            {"Commercial "}
          </MenuItem>
          <MenuItem key={"Industrial "} value={"Industrial "}>
            {"Industrial "}
          </MenuItem>{" "}
          <MenuItem key={"Land "} value={"Land "}>
            {"Land "}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
