function INPUT(props) {
  return (
    <div>
      <input
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className="border-2 outline-blue-300 p-2 rounded-md w-full"
        type={props.type}
        disabled={props.disabled}
      ></input>
    </div>
  );
}

export default INPUT;
