import { Link } from "react-router-dom";

function SECTION_3() {
  return (
    <div className="py-7 flex flex-col gap-10 md:grid grid-cols-2 w-full px-[10px] md:px-[70px] ">
      <div className=" w-full">
        <SECTION_HEADER
          header={"Want to be part of Our trusted Agent community?"}
          discription={"Join our platform as a trusted agent"}
          discription_sm={"Join our platform as a trusted agent"}
        />
        <Link to={"/create account?role=agent"}>
          <button className="hidden md:block btn bg-p1 rounded-[4px] outline-none border-none text-white min-w-[289px] min-h-[58px] font-semibold text-lg ">
            Join Now As Agent
          </button>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center">
        <POINT
          head={"Access to Quality Leads"}
          dis={
            "Gain access to a pool of qualified sellers actively seeking representation."
          }
        />
        <POINT
          head={"Enhanced Visibility"}
          dis={
            "Increase your visibility and reach among potential clients by showcasing your expertise and Bidding By Presenting Your Competitive Advantage."
          }
        />
        <POINT
          head={"Subscription Benefits"}
          dis={
            "Access premium features and resources with our subscription plans tailored for agents."
          }
        />
        <Link to={"/create account?role=agent"}>
          <button className="md:hidden block btn bg-p1 rounded-[4px] outline-none border-none text-white min-w-[217px] min-h-[46px] font-semibold text-base ">
            Join Now As Agent
          </button>
        </Link>
      </div>
    </div>
  );
}

export default SECTION_3;
function POINT(props) {
  return (
    <div className="flex flex-col items-center md:items-start justify-center md:grid grid-cols-10 gap-2 pb-10 ">
      <div className="col-span-9 md:flex flex-col items-center justify-center text-center md:text-start md:items-start">
        <h1 className="text-xl md:text-4xl font-medium">{props.head}</h1>
        <h1 className="text-sm md:text-base font-medium max-w-[295px] text-[#8F90A6] md:w-[390px] md:max-w-[390px] pt-4">
          {props.dis}
        </h1>
      </div>
    </div>
  );
}

function SECTION_HEADER(props) {
  return (
    <div className="flex md:block flex-col items-center text-center md:text-start  w-full">
      <h1 className="text-[24px] sm:text-[40px] max-w-[529px]   font-semibold capitalize">
        {props.header}
      </h1>
      <h1 className="hidden sm:block font-medium text-[16px] text-[#8F90A6] capitalize py-5">
        {props.discription}
      </h1>
      <h1 className="font-medium text-[16px] text-[#8F90A6] block sm:hidden capitalize py-5">
        {props.discription_sm}
      </h1>
    </div>
  );
}
