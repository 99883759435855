import { Search } from "@mui/icons-material";
import MODAL_BID_DETAILS from "../../components/Modal_Bid_Details";
import { MY_Property_Bids } from "../../Scripts/API";
import { useEffect, useState } from "react";

function BIDS() {
  const [my_prop_bids, set_my_prop_bids] = useState([]);
  const [property_data, set_property_data] = useState({});
  const [loading, setloading] = useState(false);
  const [filter, setfilter] = useState("");
  const [filtered_data, setfiltered_data] = useState([]);

  const fetchdata = async () => {
    try {
      setloading(true);
      const data = await MY_Property_Bids();
      set_my_prop_bids(data);
      setfiltered_data(data);
    } catch (err) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (filter === "") {
      return setfiltered_data(my_prop_bids);
    }
    setfiltered_data(
      my_prop_bids.filter((item) => item.property_title.includes(filter))
    );
  }, [filter]);

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <div className="w-screen px-[10px] md:px-[70px]">
      <MODAL_BID_DETAILS data={property_data} />
      <div className="flex sm:flex-row flex-col gap-4 items-start md:items-center justify-between my-8 ">
        <h1 className="text-xl md:text-2xl font-medium">Bids</h1>
        <div className="border rounded-md flex items-center gap-2 px-4 py-2  w-full sm:w-[400px]">
          <Search />
          <input
            value={filter}
            onChange={(e) => {
              setfilter(e.target.value);
            }}
            placeholder="Search by Property Name"
            className="outline-none"
            cols={6}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-4 py-4  md:hidden">
        {filtered_data.map((d, i) => (
          <TILE key={i} data={d} set_property_data={set_property_data} />
        ))}
      </div>

      <div className="mb-14 w-full md:flex  items-start justify-center text-[#667085]  hidden  h-80 ">
        <TABLE data={filtered_data} set_property_data={set_property_data} />
      </div>
    </div>
  );
}

export default BIDS;
function TABLE(props) {
  return (
    <div className="w-[1090px]">
      <div className="flex gap-6 bg-[#FCFCFD] w-full items-center rounded-t-md border p-2">
        <div className="w-[44px] flex items-center justify-center">
          <input className=" checkbox checkbox-sm rounded-md" type="checkbox" />
        </div>
        <div className={`grid grid-cols-6 w-full text-xs font-medium`}>
          <h1>Property Name</h1>
          <h1>Agent Name</h1>
          <h1>Email</h1>
          <h1>Propert Price</h1>
          <h1>Commission Rate</h1>
          <h1>Actions</h1>
        </div>
      </div>
      <div className="h-60 overflow-y-scroll my-scrollbar">
        {props.data.map((d, i) => (
          <ROW
            key={i}
            index={i}
            data={d}
            set_property_data={props.set_property_data}
          />
        ))}
      </div>
    </div>
  );
}

function ROW(props) {
  return (
    <div className="flex gap-6  w-full items-center  border-b  p-2">
      <div className="w-[44px] flex items-center justify-center">
        <h1>{props.index + 1}</h1>
      </div>
      <div className={`grid grid-cols-6 w-full text-xs `}>
        <h1 className="font-semibold text-sm text-black">
          {props.data.property_title}
        </h1>
        <h1> {props.data.agent_name}</h1>
        <h1>{props.data.agent_email}</h1>
        <h1>${props.data.property_price}</h1>
        <h1>{props.data.agent_commision}</h1>
        <h1
          onClick={() => {
            props.set_property_data(props.data);
            document.getElementById("BID_DETAIL").showModal();
          }}
          className="text-sm text-s1 font-medium cursor-pointer"
        >
          View Details
        </h1>
      </div>
    </div>
  );
}

function TILE(props) {
  return (
    <div className="p-4 bg-white shadow-md drop-shadow-md rounded-md w-[340px] text-sm text-[#667085]">
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <h1>Propert Name</h1>
            <h1 className="text-black font-medium">
              {props.data.property_title}
            </h1>
          </div>
          <div>
            <h1>Agent Name</h1>
            <h1 className="text-black font-medium">{props.data.agent_name}</h1>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 ">
          <div>
            <h1>Email</h1>
            <h1 className="text-black font-medium overflow-hidden overflow-ellipsis ">
              {props.data.agent_email}
            </h1>
          </div>
          <div>
            <h1>Property Price</h1>
            <h1 className="text-black font-medium">
              ${props.data.property_price}
            </h1>
          </div>
        </div>{" "}
        <div className="grid grid-cols-2 gap-2 ">
          <div>
            <h1>Commission Rate</h1>
            <h1 className="text-black font-medium overflow-hidden overflow-ellipsis ">
              {props.data.agent_commision}
            </h1>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          props.set_property_data(props.data);
          document.getElementById("BID_DETAIL").showModal();
        }}
        className="flex items-center  text-center btn btn-ghost w-full"
      >
        <h1 className="text-sm text-s1 font-medium cursor-pointer w-full">
          View Details
        </h1>
      </button>
    </div>
  );
}
