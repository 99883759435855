import { Close } from "@mui/icons-material";

function Enlarge_Image(props) {
  return (
    <dialog className="modal container" id="img_cnt">
      <div className="modal-box flex items-center justify-center relative">
        <button
          onClick={() => {
            document.getElementById("img_cnt").close();
          }}
          className="close absolute top-1 right-1 text-xl  "
        >
          <Close />
        </button>
        <img src={props.imag} alt="" />
      </div>{" "}
    </dialog>
  );
}

export default Enlarge_Image;
