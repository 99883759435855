import { Google } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Logo from "../images/logo.png";
import banner from "../images/container.png";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import { SignIn, SignIn_Google } from "../Scripts/API";
import { Helmet, HelmetProvider } from "react-helmet-async";

function LOGIN() {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  async function onSuccess(x) {
    if (!x.credential) {
      toast.error(x);
    }
    const res = await SignIn_Google(x.credential);
    if (res.status === false) {
      return toast.error(res.msg);
    }
    const { token, role } = res;
    if (!token) {
      toast.error("Invalid Creditionals");
    } else {
      if (rememberMe) {
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("role");
      } else {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("role", role);
        localStorage.removeItem("token");
        localStorage.removeItem("role");
      }
      if (role === "agent") {
        return (window.location.href = "/agent_bids");
      }
      if (role === "seller") {
        return (window.location.href = "/myproperties");
      }
    }
  }

  async function onFailure(x) {
    console.log(x);
    toast.error("Invalid User");
  }

  const handleSubmit = async (e) => {
    try {
      setloading(true);
      const res = await SignIn(email, password, rememberMe);
      const { token, role } = res;
      if (!token) {
        toast.error("Invalid Creditionals");
      } else {
        if (rememberMe) {
          localStorage.setItem("token", token);
          localStorage.setItem("role", role);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("role");
        } else {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("role", role);
          localStorage.removeItem("token");
          localStorage.removeItem("role");
        }
        if (role === "agent") {
          return (window.location.href = "/agent_bids");
        }
        if (role === "seller") {
          return (window.location.href = "/myproperties");
        }
        window.location.href = "/";
      }
    } catch (e) {
      console.log(e);
    } finally {
      setloading(false);
    }
  };
  return (
    <HelmetProvider>
    <div className="grid md:grid-cols-2  w-screen h-screen p-2 gap-4  ">
      <Helmet>
          <title>Welcome to Reagent Bid</title>
          <meta name="description" content="Brief description for better SEO and knowledge panel" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Welcome to Reagent Bid",
                "url": "https://www.reagentbid.com/login",
                "description": "Connect With Trusted Real Estate Agents. Receive multiple proposals from the competing agents and chose the right agent at the lowest cost to you",
                "publisher": {
                  "@type": "Real Estate Agent",
                  "name": "Reagent Bid",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.yourwebsite.com/logo.jpg"
                  }
                }
              }
            `}
          </script>
        </Helmet>
      <Toaster />
      <div className="bg-white flex items-center flex-col justify-center  gap-8 h-full">
        <div className="md:fixed  md:top-4 md:left-4 ">

        <Link to={"/"}><img src={Logo} alt="logo" /></Link>        </div>
        <div className="w-full  items-center justify-center flex md:h-full ">
          <div className=" flex flex-col   w-[340px] md:w-[400px] md:gap-8 gap-4 ">
            <div className="text-center md:text-start gap-2 flex flex-col">
              <h1 className="font-semibold md:text-[40px] text-2xl">Sign in</h1>
              <h1 className="text-[#969696] ">
                Please login to continue to your account.
              </h1>
            </div>
            <TextField
              disabled={loading}
              required
              id="outlined-required"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ borderRadius: 20, MozOutlineRadius: 20 }}
            />

            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                disabled={loading}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="flex gap-4  items-center">
              <input
                value={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
                className="checkbox checkbox-sm rounded-sm border-black  "
                type="checkbox"
              />
              <label>Keep me logged in</label>
            </div>

            <button
              disabled={
                !password ||
                !email ||
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
                loading
              }
              onClick={() => handleSubmit()}
              className="btn rounded-sm bg-s1 text-white font-semibold  border-none text-lg"
            >
              Sign in
            </button>
            <div className="flex items-center justify-center">
              <div className="w-full border-b border-t-1" />
              <label className="px-2">or</label>
              <div className="w-full border-b border-t-1" />
            </div>
            <GoogleLogin
              useOneTap
              onSuccess={(x) => onSuccess(x)}
              onError={(x) => onFailure(x)}
            />
            <div className="md:flex text-center items-center justify-center gap-1">
              <span className="text-[#6C6C6C]">Need an account?</span>
              <Link to={"/create account"}>
                <span className="underline font-medium text-s1">
                  Create one
                </span>
              </Link>

              <Link to={"/forgot password"}>
                <h1 className="underline font-medium text-[#6C6C6C]">
                  Password Reset
                </h1>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" hidden md:flex justify-end ">
        <img src={banner} alt="banner" />
      </div>
    </div>
    </HelmetProvider>
  );
}

export default LOGIN;
