
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import "./css/index.css";

import DefaultLayout from "./default";
import Home from "./pages/App_Pages/Home";
import Listing from "./pages/App_Pages/Listing";
import CONTACT_US from "./pages/App_Pages/Contact_us";
import LOGIN from "./pages/Login";
import CREATE_ACCOUNT from "./pages/Create_Account";
import FORGOT_PWD from "./pages/Forgot_Pwd";
import RESET_PWD from "./pages/Reset_Pwd";
import MY_PROPERTIES from "./pages/Agent_Pages/Properties";
import BIDS from "./pages/Seller_pages/Bids";
import AGENT_PROFILE from "./pages/Agent_Pages/Agent_Profile";
import PROFILE_SELLER from "./pages/Seller_pages/Seller_Profile";
import SELLER_BIDS from "./pages/Agent_Pages/Agent_Bids";
import PROPERTY from "./pages/App_Pages/Property";
import TermsAndConditions from "./pages/App_Pages/Terms_and_Conditions";

import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  return (
    <GoogleOAuthProvider clientId="84284468648-bp94m07kag1necgf7qapg3kjbao0bn3v.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route
            path="/termsandconditions"
            element={
              <DefaultLayout>
                <TermsAndConditions />
              </DefaultLayout>
            }
          />
          <Route
            path="/property/:propid"
            element={
              <DefaultLayout>
                <PROPERTY />
              </DefaultLayout>
            }
          />
          <Route
            path="/login"
            element={
              IS("seller") || IS("agent") ? <Navigate to="/" /> : <LOGIN />
            }
          />
          <Route
            path="/"
            element={
              <DefaultLayout>
                <Home />
              </DefaultLayout>
            }
          />
          <Route
            path="/Listings"
            element={
              <DefaultLayout>
                <Listing />
              </DefaultLayout>
            }
          />
          <Route
            path="/contact us"
            element={
              <DefaultLayout>
                <CONTACT_US />
              </DefaultLayout>
            }
          />
          <Route path="/Create account" element={<CREATE_ACCOUNT />} />
          <Route path="/forgot password" element={<FORGOT_PWD />} />
          <Route path="/reset password" element={<RESET_PWD />} />
          <Route path="*" element={<h1>404 Page Not Found</h1>} />
          <Route
            path="/myproperties"
            element={
              IS("seller") ? (
                <DefaultLayout>
                  <MY_PROPERTIES />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/seller_bids"
            element={
              IS("seller") ? (
                <DefaultLayout>
                  <BIDS />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/agent_bids"
            element={
              IS("agent") ? (
                <DefaultLayout>
                  <SELLER_BIDS />
                </DefaultLayout>
              ) : (
                <Navigate to={"/login"} />
              )
            }
          />
          <Route
            path="/profileagent"
            element={
              IS("agent") ? (
                <DefaultLayout>
                  <AGENT_PROFILE />
                </DefaultLayout>
              ) : (
                <Navigate to={"/login"} />
              )
            }
          />
          <Route
            path="/profilseller"
            element={
              IS("seller") ? (
                <DefaultLayout>
                  <PROFILE_SELLER />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

function isSeller() {
  let role = localStorage.getItem("role") || sessionStorage.getItem("role");
  let token = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (role === "seller" && token) {
    return true;
  }
  return false;
}
function IS(ROLE) {
  let role = localStorage.getItem("role") || sessionStorage.getItem("role");
  let token = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (role === ROLE && token) {
    return true;
  }
  return false;
}
root.render(<App />);
