import { ModalHeader } from "../Modal_Header";
import {
  BedOutlined,
  Check,
  DeleteForever,
  RestorePage,
  ShowerOutlined,
} from "@mui/icons-material";
import { DimensionsIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import {
  Delete_Property_Images,
  Edit_Property,
  Upload_Property_Images,
} from "../../Scripts/API";
import toast from "react-hot-toast";
import AWSUpload from "../AWS_Image_Upload";

function EditProperty(props) {
  const [property_title, set_property_title] = useState("");
  const [propert_address, set_property_address] = useState("");
  const [bedrooms, set_bedrooms] = useState(0);
  const [bathrooms, set_bathrooms] = useState(0);
  const [asking_price, set_asking_price] = useState(0);
  const [property_area, set_property_area] = useState(0);
  const [property_detail, set_property_detail] = useState("");
  const [property_type, set_property_type] = useState("");
  const [Balcony, set_balcony] = useState(false);
  const [Garage, set_garage] = useState(false);
  const [Garden, set_garden] = useState(false);
  const [Parking, set_parking] = useState(false);
  const [Images, set_images] = useState([]);
  const [_Remove_Images, set_Remove_images] = useState([]);
  const [FILEs, set_FILEs] = useState([]);

  const [submit, set_submit] = useState(false);
  const [uploading_images, setuploading_images] = useState(false);
  const [removing_images, setremoving_images] = useState(false);

  const [loading, setloading] = useState(false);

  const [reset, setreset] = useState(false);

  function Remove_Image(img) {
    const updatedImages = Images.filter((image) => image !== img);
    set_images(updatedImages);
    set_Remove_images([..._Remove_Images, img]);
  }

  useEffect(() => {
    set_FILEs([]);
    set_Remove_images([]);
    set_images(props.property.Images);
    set_property_title(props.property.property_title);
    set_property_address(props.property.propert_address);
    set_bedrooms(props.property.bedrooms);
    set_bathrooms(props.property.bathrooms);
    set_asking_price(props.property.asking_price);
    set_property_area(props.property.property_area);
    set_property_detail(props.property.property_detail);
    set_property_type(props.property.property_type);
    set_balcony(props.property.Balcony);
    set_garage(props.property.Garage);
    set_garden(props.property.Garden);
    set_parking(props.property.Parking);
    set_submit(props.property.submit);
  }, [props.property, reset]);

  async function post() {
    setloading(true);
    set_submit(true);
    try {
      const data = {
        property_title,
        propert_address,
        bedrooms,
        bathrooms,
        asking_price,
        property_area,
        property_detail,
        property_type,
        Balcony,
        Garage,
        Garden,
        Parking,
        Images,
      };
      const res = await Edit_Property(props.property._id, data);
      setremoving_images(true);
      await Delete_Property_Images(_Remove_Images);
      setuploading_images(true);
      await Upload_Property_Images(props.property._id, FILEs);

      if (res.status) {
        toast.success("Property updated successfully");
        window.location.reload();
      }
    } catch (e) {
      toast.error("Error");
    } finally {
      setloading(false);
    }
  }
  return (
    <dialog id="edit property" className="modal ">
      <div className=" modal-box  my-scrollbar p-0 m-0">
        <div className="relative p-6 ">
          {submit && (
            <div className="absolute bg-[rgba(0,0,0,0.8)] z-50 w-full h-full  top-0 left-0 flex items-center justify-center">
              <div className="text-white text-center">
                <span className="loading-spinner loading loading-lg text-white"></span>
                {submit && (
                  <div className="flex  justify-between w-64">
                    <h1>Updating Property</h1>{" "}
                    {submit && removing_images && <Check />}
                  </div>
                )}
                {removing_images && (
                  <div className="flex  justify-between w-64">
                    <h1>Reviewing old images and data</h1>{" "}
                    {submit && removing_images && uploading_images && <Check />}
                  </div>
                )}

                {uploading_images && (
                  <div className="flex  justify-between w-64">
                    <h1>Uploading New Images</h1>{" "}
                    {!submit && removing_images && uploading_images && (
                      <Check />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="flex ">
            <ModalHeader heading="Edit Property Details" />
            <button
              onClick={() => {
                setreset(!reset);
              }}
            >
              <RestorePage />
            </button>
          </div>
          <div className="grid grid-cols-2 items-center my-1 mt-2">
            <h1>Name</h1>
            <input
              maxLength={40}
              value={property_title || ""}
              onChange={(e) => {
                set_property_title(e.target.value);
              }}
              type="text"
              placeholder="Property Name"
              className="p-2 border rounded-md  outline-none"
            />
          </div>
          <div className="grid grid-cols-2 items-center my-1">
            <h1>Zip Code</h1>
            <input
              value={propert_address}
              onChange={(e) => {
                let value = e.target.value.toUpperCase();
                const regex = /^[a-zA-Z0-9-]{0,9}$/;
                if (!regex.test(value)) {
                  return toast.error("Invalid Zipcode");
                }
                set_property_address(value);
              }}
              type="text"
              placeholder="Property Name"
              className="p-2 border rounded-md outline-none"
            />
          </div>
          <div className="grid grid-cols-2 items-center my-1">
            <h1>Asking Price</h1>
            <input
              value={asking_price}
              onChange={(e) => {
                set_asking_price(e.target.value);
              }}
              type="number"
              placeholder="Property Name"
              className="p-2 border rounded-md outline-none"
            />
          </div>
          <DROP_DOWN v={property_type} set={set_property_type} />
          <div className="flex gap-2 items-center justify-center my-1">
            <div className="flex gap-2 items-center ">
              <BedOutlined />
              <input
                value={bedrooms}
                onChange={(e) => {
                  set_bedrooms(e.target.value);
                }}
                type="number"
                className="border rounded-md outline-none p-1 w-[80px]"
              />
            </div>

            <div className="flex gap-2 items-center ">
              <ShowerOutlined />
              <input
                value={bathrooms}
                onChange={(e) => {
                  set_bathrooms(e.target.value);
                }}
                type="number"
                className="border rounded-md outline-none p-1 w-[80px]"
              />
            </div>

            <div className="flex gap-2 items-center ">
              <DimensionsIcon style={{ width: "24px" }} />
              <input
                value={property_area.$numberDecimal}
                onChange={(e) => {
                  set_property_area(e.target.value);
                }}
                type="number"
                className="border rounded-md p-1 w-[80px] outline-none"
              />
            </div>
          </div>
          <textarea
            maxLength={1000}
            style={{ resize: "none" }}
            value={property_detail}
            onChange={(e) => set_property_detail(e.target.value)}
            className="w-full p-[12px] border border-[#E0E0E0] placeholder:text-sm outline-none h-[200px] rounded-md my-1"
            placeholder="Brief detail of the Property and special features"
          />
          <div className="flex flex-col  gap-1  w-full    h-full bg-slate-200 rounded-md  my-1 p-2">
            <SWITCH name={"Balcony"} value={Balcony} set={set_balcony} />
            <SWITCH name={"Garage"} value={Garage} set={set_garage} />
            <SWITCH name={"Garden"} value={Garden} set={set_garden} />
            <SWITCH name={"Parking"} value={Parking} set={set_parking} />
          </div>

          <div className="flex  gap-2">
            {Images.map((image, i) => (
              <div
                key={i}
                className="w-16 border rounded flex items-center justify-center bg-slate-50 my-2 relative"
              >
                <button
                  onClick={() => {
                    Remove_Image(image);
                  }}
                  className="absolute  text-red-500 rounded-full -top-3 -right-3"
                >
                  <DeleteForever style={{ fontSize: "24px" }} />
                </button>
                <img src={image} alt="" />
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-[10px] items-start">
            <h1 className="text-sm text-[#828282]">Add New Propery Images </h1>
            <AWSUpload set_FILEs={set_FILEs} />
            <h1 className="text-sm text-[#828282]">
              {FILEs.length} image(s) selected
            </h1>
          </div>
          <button
            disabled={
              loading ||
              !propert_address ||
              !property_title ||
              !property_area ||
              !property_type ||
              !asking_price ||
              !property_detail ||
              !bedrooms ||
              !bathrooms ||
              !property_area
            }
            onClick={post}
            className="btn w-full my-1 bg-s1 text-white"
          >
            Confirm
          </button>
        </div>
      </div>
    </dialog>
  );
}

function SWITCH(props) {
  return (
    <div
      className={`flex justify-between px-4   ${
        props.active ? "text-black" : "text-[#828282]"
      }`}
    >
      <h1>{props.name}</h1>
      <input
        type="checkbox"
        className="toggle toggle-sm "
        checked={props.value}
        onChange={() => props.set(!props.value)}
      />
    </div>
  );
}

export default EditProperty;

function DROP_DOWN(props) {
  return (
    <select
      className="w-full p-2 bg-slate-200 rounded-md font-medium my-1 outline-none"
      value={props.v}
      onChange={(e) => {
        props.set(e.target.value);
      }}
    >
      <option key={""} value={""}>
        {"Un-specfied"}
      </option>
      <option key={"House"} value={"House"}>
        {"House"}
      </option>
      <option key={"Apartment"} value={"Apartment"}>
        {"Apartment"}
      </option>
      <option key={"Condominium "} value={"Condominium "}>
        {"Condominium "}
      </option>
      <option key={"Townhouse"} value={"Townhouse"}>
        {"Townhouse"}
      </option>
      <option key={"Duplex/Triplex"} value={"Duplex/Triplex"}>
        {"Duplex/Triplex"}
      </option>
      <option key={"Mobile/Manufactured"} value={"Mobile/Manufactured"}>
        {"Mobile/Manufactured"}
      </option>
      <option key={"Commercial "} value={"Commercial "}>
        {"Commercial "}
      </option>
      <option key={"Industrial "} value={"Industrial "}>
        {"Industrial "}
      </option>{" "}
      <option key={"Land "} value={"Land "}>
        {"Land "}
      </option>
    </select>
  );
}

function IMAGE(props) {
  return (
    <div className="relative  ">
      <button
        onClick={() => props.Remove_Image(props.src)}
        className="-top-2 -right-2 btn btn-xs p-0 m-0  border-none outline-none bg-red-500 text-white absolute"
      >
        <DeleteForever />
      </button>
      <img alt={props.index} src={props.src} className=" rounded-md" />
    </div>
  );
}
