import {
  DeleteForever,
  Edit,
  Folder,
  OpenInBrowser,
  OpenInNew,
  PictureAsPdf,
  Search,
} from "@mui/icons-material";
import { My_Bids } from "../../Scripts/API";
import { useEffect, useState } from "react";
import ClickbaitPopup from "../../components/ClickBait";
import EditBid from "../../components/Modals/Edit_bid";
import EditResumeFile from "../../components/Modals/Edit_Resume_File";
import Delete_Bid from "../../components/Modals/Delete_Bid";

function AGENT_BIDS() {
  const [bids, setbids] = useState([]);
  const [bid, setbid] = useState(bids[0]);
  const [loading, setloading] = useState(false);
  const [serch, setserch] = useState("");
  const [filtered_data, setfiltered] = useState(bids);

  const fetchdata = async () => {
    try {
      setloading(true);
      const response = await My_Bids();
      if (response.Error) {
        return;
      }
      setbids(response);
    } catch (err) {
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    if (serch !== "") {
      setfiltered(
        bids.filter((item) =>
          item.propert_title.toLowerCase().includes(serch.toLowerCase())
        )
      );
    } else {
      setfiltered(bids);
    }
  }, [serch, bids]);

  return (
    <div className="w-screen px-[10px] md:px-[70px]">
      <EditResumeFile bid={bid} />
      <Delete_Bid bid={bid}/>
      <ClickbaitPopup
        text="search for the potential property"
        link="/Listings"
      />
      <EditBid data={bid} />
      <div className="flex sm:flex-row flex-col gap-4 items-start md:items-center justify-between my-8 ">
        <h1 className="text-xl md:text-2xl font-medium">My Bids (agent)</h1>
        <div className="border rounded-md flex items-center gap-2 px-4 py-2  w-full sm:w-[400px]">
          <Search />
          <input
            placeholder="Search"
            className="outline-none"
            cols={6}
            value={serch}
            onChange={(e) => setserch(e.target.value)}
          />
        </div>
      </div>

      <TABLE_SM data={filtered_data} f={setbid} />
      <div className="mb-14 w-full md:flex  items-center justify-center text-[#667085]  hidden ">
        <TABLE data={filtered_data} f={setbid} />
      </div>
    </div>
  );
}

export default AGENT_BIDS;

function TABLE(props) {
  return (
    <div className="w-[1090px]">
      <div className="flex gap-6 bg-[#FCFCFD] w-full items-center rounded-t-md border p-2">
        <div className="w-[44px] flex items-center justify-center">
          <input className=" checkbox checkbox-sm rounded-md" type="checkbox" />
        </div>
        <div className={`grid grid-cols-5 w-full text-xs font-medium`}>
          <h1>Property Name</h1>
          <h1>Commission Rate</h1>
          <h1>Contract Length</h1>
          <h1>Marketing Budget</h1>
          <h1>Propert Price</h1>
        </div>
        <div className="w-[120px] flex items-center justify-center"></div>
      </div>
      {props.data?.map((d, i) => (
        <ROW key={i} index={i} data={d} f={props.f} />
      ))}
    </div>
  );
}

function ROW(props) {
  return (
    <div className="flex gap-6  w-full items-center  border-b  p-2">
      <div className="w-[44px] flex items-center justify-center">
        <h1>{props.index + 1}</h1>
      </div>
      <div className={`grid grid-cols-5 w-full text-xs `}>
        <h1 className="font-semibold text-sm text-black">
          {props.data.propert_title}
        </h1>
        <h1>{props.data.commision_rate} </h1>
        <h1> {props.data.contract_length} </h1>
        <h1>{props.data.budget.toLocaleString()}</h1>
        <h1>${props.data.property_price.toLocaleString()}</h1>
      </div>

      <div className="w-[120px] flex items-center justify-center gap-2 ">
       
      <Actions f={props.f} data={props.data}/>
      </div>
    </div>
  );
}

function TABLE_SM(props) {
  return (
    <div className="flex flex-col items-center justify-center gap-4 py-4  md:hidden">
      {props.data?.map((d, i) => (
        <TILE key={i} data={d} f={props.f} />
      ))}
    </div>
  );
}

function TILE(props) {
  return (
    <div className="p-4 bg-white shadow-md drop-shadow-md rounded-md w-[340px] text-sm text-[#667085]">
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <h1>Propert Name</h1>
            <h1 className="text-black font-medium">
              {props.data.propert_title}
            </h1>
          </div>
          <div>
            <h1>Property Price</h1>
            <h1 className="text-black font-medium">
              {props.data.property_price}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 ">
          <div>
            <h1>Commission Rate</h1>
            <h1 className="text-black font-medium overflow-hidden overflow-ellipsis ">
              {props.data.commision_rate}
            </h1>
          </div>
          <div>
            <h1>Length of Contract</h1>
            <h1 className="text-black font-medium overflow-hidden overflow-ellipsis ">
              {props.data.contract_length}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 ">
          <div>
            <h1>Marketing Budget</h1>
            <h1 className="text-black font-medium">{props.data.budget}</h1>
          </div>
        </div>

        <Actions f={props.f} data={props.data}/>
      </div>
      
    </div>
  );
}

function Actions(props){
  return(
    <div className=" flex items-center justify-center gap-2 w-full my-2">
      <button 
      onClick={()=>{
        
        props.f(props.data);
        document.getElementById("DelBid").showModal()}}
          className="btn btn-xs p-0 m-0 ">
            <DeleteForever style={{ fontSize: "18px" }}/>

      </button>
      <button
          onClick={() => {
            props.f(props.data);
            document.getElementById("editresume").showModal();
          }}
          className="btn btn-xs p-0 m-0 "
        >
          <Folder style={{ fontSize: "18px" }} />
        </button>

        <button
          onClick={() => {
            props.f(props.data);

            document.getElementById("EditBid").showModal();
          }}
          className="btn btn-xs p-0 m-0 "
        >
          <Edit style={{ fontSize: "18px" }} />
        </button>
        <a
          href={`/property/${props.data.property_id}`}
          target="_blank"
          className="btn btn-xs p-0 m-0 "
        >
          <OpenInNew style={{ fontSize: "18px" }} />
        </a>
      </div>
  )
}
