import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import {
  AccountCircle,
  AccountCircleOutlined,
  CardMembershipOutlined,
  HomeOutlined,
  KeyboardArrowDown,
  LoginOutlined,
  Menu,
  MonetizationOnOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";
import { useEffect, useState, useRef} from "react";

function NAVBAR() {
  const [isLogedin, setlogin] = useState(false);
  const [agent, setagent] = useState(false);
  const [seller, setseller] = useState(false);

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }

  useEffect(() => {
    let role = localStorage.getItem("role") || sessionStorage.getItem("role");
    let token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (role === "agent") {
      setagent(true);
    }
    if (role === "seller") {
      setseller(true);
    }
    if (token) {
      setlogin(true);
    }
  }, []);

  return (
    <div className="navbar  flex justify-between px-[10px] md:px-[70px]">
     <Link to={"/"}>
      <img
        src={Logo}
        alt="logo"
        className="w-[87px] h-[69.12px] sm:h-[96.33px] sm:w-[121.24px]"
      /></Link>
      <LINKS />
      {isLogedin && agent ? (
        <AGENT_PROFILE logout={logout} />
      ) : isLogedin && seller ? (
        <SELLER_PROFILE logout={logout} />
      ) : (
        <SIGN_BTN />
      )}
      <label htmlFor="my-drawer-4" className="sm:hidden block">
        <Menu
          className="text-p1  rounded-full hover:cursor-pointer "
          style={{ fontSize: "24px" }}
        />
      </label>
    </div>
  );
}

export default NAVBAR;

function LINKS() {
  const ActiveStyle = "underline font-[600] text-s1";
  const InActiveStyle = "font-[500]";
  return (
    <div className="gap-[49px] hidden sm:flex">
      <NavLink
        to={"/"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Home
      </NavLink>

      <NavLink
        to={"/Listings"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Listings
      </NavLink>

      <NavLink
        to={"/Contact us"}
        className={({ isActive }) =>
          ` text-[16px] ${isActive ? ActiveStyle : InActiveStyle}`
        }
      >
        Contact us
      </NavLink>
    </div>
  );
}

function SIGN_BTN() {
  return (
    <div>
      <Link to="/login">
        <button className=" hidden sm:block btn btn-sm text-white bg-p1 p-0 m-0 font-[600] text-[18px] w-[137px] h-[46px]">
          Sign In
        </button>
      </Link>
    </div>
  );
}

function SELLER_PROFILE(props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    setDropdownOpen(false);
  };

  return (
    <div
      className="flex items-center justify-center gap-2"
      style={{ zIndex: 2 }}
    >
      <h1 className="font-[600] text-s1">Property Seller</h1>
      <div ref={dropdownRef}>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="hover:bg-slate-200 rounded p-2"
            onClick={handleToggleDropdown}
          >
            <AccountCircle className="text-s1" style={{ fontSize: "36px" }} />
            <KeyboardArrowDown className="ml-2" />
          </div>
          {isDropdownOpen && (
            <ul
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-sm w-max font-medium text-sm"
            >
              <li>
                <Link to="/myproperties" onClick={handleLinkClick}>
                  <HomeOutlined />
                  <label>My Properties</label>
                </Link>
              </li>
              <li>
                <Link to="/seller_bids" onClick={handleLinkClick}>
                  <MonetizationOnOutlined />
                  <label>Bids</label>
                </Link>
              </li>
              <li>
                <Link to="/profilseller" onClick={handleLinkClick}>
                  <AccountCircleOutlined />
                  <label>My Profile</label>
                </Link>
              </li>
              <li>
                <button onClick={() => { props.logout(); handleLinkClick(); }}>
                  <LoginOutlined />
                  <label>Logout</label>
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}


function AGENT_PROFILE(props) {
  return (
    <div
      className="flex items-center justify-center gap-2  "
      style={{ zIndex: 2 }}
    >
      <h1 className="font-[600] text-s1"> Property Agent</h1>
      <div>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="hover:bg-slate-200 rounded p-2 "
          >
            <AccountCircle className="text-s1" style={{ fontSize: "36px" }} />
            <KeyboardArrowDown className="ml-2 " />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-sm w-max font-medium text-sm"
          >
            <li>
              <Link to={"/agent_bids"}>
                <MonetizationOnOutlined />
                <label>My Bids</label>
              </Link>
            </li>
            <li>
              <Link>
                <CardMembershipOutlined />
                <label>Subscriptions</label>
              </Link>
            </li>
            <li>
              <Link to={"/profileagent"}>
                <AccountCircleOutlined />
                <label>My Profile</label>
              </Link>
            </li>
            <li>
              <button onClick={() => props.logout()}>
                <LoginOutlined />
                <label>Logout</label>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function DEBUG(props) {
  return (
    <div className="  bg-white opacity-5 hover:opacity-100 fixed top-52 left-10 rounded-lg shadow-lg  flex flex-col gap-1 border p-2">
      <h1>Debug</h1>
      <div className="grid grid-cols-4  items-center ">
        <h1 className="col-span-3">Agent</h1>
        <input
          type="checkbox"
          className="toggle toggle-success toggle-xs"
          checked={props.agent}
          onChange={() => props.setagent(!props.agent)}
        />
      </div>
      <div className="grid grid-cols-4  items-center ">
        <h1 className="col-span-3">Seller</h1>
        <input
          type="checkbox"
          className="toggle toggle-success toggle-xs"
          checked={props.seller}
          onChange={() => props.setseller(!props.seller)}
        />
      </div>
      <div className="grid grid-cols-4  items-center ">
        <h1 className="col-span-3">Logedin</h1>
        <input
          type="checkbox"
          className="toggle toggle-success toggle-xs"
          checked={props.isLogedin}
          onChange={() => props.setlogin(!props.isLogedin)}
        />
      </div>
    </div>
  );
}
