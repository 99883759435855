import {
  ArrowBack,
  BedOutlined,
  ContentCopyOutlined,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  ShowerOutlined,
} from "@mui/icons-material";
import { DimensionsIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Enlarge_Image from "../../components/Modals/Enlarge_Image";

function LISTING_DETAILS(props) {
  const [isagent, set_isagent] = useState(false);
  useEffect(() => {
    const x = localStorage.getItem("role") || sessionStorage.getItem("role");
    if (x === "agent") {
      set_isagent(true);
    }
  }, []);
  return (
    <div className="w-screen  px-[10px] sm:px-[70px] mb-20">
      <div
        onClick={() => props.set_View_Deatils(false)}
        className="flex gap-4 items-center text-p2 my-10 w-fit hover:cursor-pointer"
      >
        <ArrowBack />
        <h1 className="text-lg font-medium">Back To Listings</h1>
        <h1>{props.d._id}</h1>
      </div>
      <div className="flex flex-col gap-10">
        <div className="md:grid grid-cols-4 gap-10   w-full ">
          <PROPERTY_MAIN d={props.d} />
          <INTERESTED
            agent={isagent}
            d={props.d}
            set_submit_bid={props.set_submit_bid}
          />
        </div>

        <div className="md:grid grid-cols-4 gap-10  flex flex-col  w-full ">
          <DETAILS d={props.d} />
          <EXTRA_DETAILS d={props.d} />
        </div>
      </div>
    </div>
  );
}

export default LISTING_DETAILS;

function INTERESTED(props) {
  return (
    <div className="border col-span-1 w-full rounded-[4px] bg-white flex  p-4 justify-evenly flex-col border-[#D9D9D9]">
      <h1 className="text-lg ">Are you interested?</h1>
      <button
        disabled={!props.agent}
        onClick={() => props.set_submit_bid(true)}
        className="btn outline-none border-none bg-p1 text-white rounded-[6px] font-semibold text-lg"
      >
        Submit Bid
      </button>
      <h1 className="text-lg">Share Property</h1>
      <button
        onClick={() => {
          navigator.clipboard.writeText(
            `http://localhost:5000/property/${props.d._id}`
          );
          toast.success("Link Copied to Clipboard");
        }}
        className="btn outline-none border-none bg-[#ECECEC] text-[#5D5E75]  rounded-[6px] font-medium "
      >
        <ContentCopyOutlined />
        Copy Link to Clipboard
      </button>
    </div>
  );
}
function PROPERTY_MAIN(props) {
  const [img_index, set_img_index] = useState(0);
  const [img_count, set_img_count] = useState(props.d.Images.length);
  return (
    <div className="md:h-[282px]  col-span-3 text-[#274868] md:flex items-center gap-10">
      {" "}
      <Enlarge_Image imag={props.d.Images[img_index]} />
      <div>
        <div>
          <div className="bg-blue-300 w-[342px] h-[282px] rounded-[16px] flex justify-center items-end p-2 relative overflow-hidden">
            <div className="w-full h-full absolute flex items-center justify-center">
              <span className="loading-spinner loading loading-lg "></span>
            </div>
            <div
              onClick={() => {
                document.getElementById("img_cnt").showModal();
              }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: `url(${props.d.Images[img_index]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: 1,
              }}
            />
            <div className="flex gap-2 z-10">
              <button
                onClick={() => set_img_index(img_index - 1)}
                disabled={img_index + 1 === 1}
                className="rounded-full bg-white btn btn-xs p-0 m-0"
              >
                <KeyboardArrowLeftRounded />
              </button>
              <div className="bg-white rounded-full px-2">
                <h1>
                  {img_index + 1}/{img_count}
                </h1>
              </div>
              <button
                onClick={() => set_img_index(img_index + 1)}
                disabled={img_index + 1 === img_count}
                className="rounded-full bg-white btn btn-xs p-0 m-0"
              >
                <KeyboardArrowRightRounded />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="  flex  flex-col gap-4 w-full m-4 md:m-0">
        <h1 className="text-2xl font-semibold">{props.d.property_title}</h1>
        <h1>{props.d.propert_address}</h1>
        <div className="flex gap-4">
          <h1 className="flex items-center gap-2">
            <BedOutlined />
            {props.d.bedrooms} bed
          </h1>
          <h1 className="flex items-center gap-2">
            <ShowerOutlined />
            {props.d.bathrooms} bath
          </h1>
          <div className="flex items-center gap-2">
            <DimensionsIcon />
            <h1>{props.d.property_area.$numberDecimal}</h1> sq. ft.
          </div>
        </div>
        <div>
          <h1>Asking Price</h1>
          <h1 className="font-medium text-3xl">${props.d.asking_price}</h1>
        </div>
      </div>
    </div>
  );
}

function DETAILS(props) {
  return (
    <div className="col-span-3 bg-[#F7FBFD] p-10">
      <h1 className="text-p1 font-semibold ">Property Details</h1>
      <h1 className="text-[#5D5E75] mt-4">{props.d.property_detail}</h1>
    </div>
  );
}

function EXTRA_DETAILS(props) {
  return (
    <div className="col-span-1 bg-[#F7FBFD] p-10">
      <h1 className="text-p1 font-semibold ">Extra Details</h1>
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Balcony</h1>
          <h1> {props.d.Balcony ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Garden</h1>
          <h1>{props.d.Garden ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Parking</h1>
          <h1>{props.d.Parking ? "Yes" : "No"}</h1>
        </div>
        <div className="flex w-full text-[#5D5E75] text-lg justify-between">
          <h1 className=" ">Garage</h1>
          <h1>{props.d.Garage ? "Yes" : "No"}</h1>
        </div>
      </div>
    </div>
  );
}
